import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import { csvParse } from "d3";
import { feature } from "topojson-client"
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import axios from 'axios';
import topojson from "./assets/USA.json";
import { toggleMenuState } from '../store/actions/action';
import "./assets/scss/explore.scss";
import Racial from './components/explore/Racial';
import Costs from './components/explore/Costs';
import Locations from './components/explore/Locations';
import StateDetail from './components/explore/StateDetail';
import Loading from './components/Loading';


class Explore extends Component {
  constructor(props) {
    super(props);
    this.width = 1146;
    this.height = 665;
    this.exploreData = null;
    this.localitiesData = [];
    this.facilitiesData = [];
    this.svgPaths = [];
    
    this.state = { 
        localitiesLoading : false
    };

    this.tabs = [
      'racial-disparity-of-incarcerated-youth',
      'youth-prison-locations',
      'costs-per-state'
    ];
  }

  componentDidMount() {
    
    // this.props._openMenu(false);
    this.svgPaths = feature(topojson, topojson.objects.states).features;
    // http://www.youthfirstinitiative.org/thefacts/data/localities.csv
    const facilitiesUrl = process.env.REACT_APP_FACILITIES_CSV,
          localitiesUrl = process.env.REACT_APP_LOCALITIES_CSV,
          exploreDataUrl = process.env.REACT_APP_API_URL + "/youthfirst/page/explore";
    axios.all([
      axios.get(exploreDataUrl), 
      axios.get(localitiesUrl),
      axios.get(facilitiesUrl)
    ])
    .then(axios.spread((exploreData, localitiesData, facilitiesData)=>{
        this.localitiesData = csvParse(localitiesData.data);
        this.facilitiesData = csvParse(facilitiesData.data);
        this.exploreData = exploreData.data;
        this.setState({localitiesLoading:true});
        // window.scrollTo(0, 0);
    }))
    .catch(function (error) {
        console.log(error);
    })
  }

  render(){
    if(this.state.localitiesLoading===false) return <Loading />;
    const isTab = this.tabs.includes(this.props.match.params.tab);
      return(
          <div>
            {isTab &&
              <div className="container">
                <div className="explore-menu">
                  <NavLink exact={true} to="/explore/racial-disparity-of-incarcerated-youth"><span>Racial Disparity of Incarcerated Youth</span></NavLink>
                  <NavLink exact={true} to="/explore/youth-prison-locations">Youth Prison Locations</NavLink>
                  <NavLink exact={true} to="/explore/costs-per-state">Costs Per State</NavLink>
                </div>
              </div>
            }
              {this.state.localitiesLoading &&
              
              
                  <Switch>
                      <Route 
                        exact 
                        path='/explore/racial-disparity-of-incarcerated-youth' 
                        render={ ()=> 
                          <Racial
                            width={this.width}
                            height={this.height} 
                            exploreData={this.exploreData}
                            svgPaths={this.svgPaths}
                            localitiesData={this.localitiesData}
                          /> } 
                      /> 

                      <Route 
                        exact 
                        path='/explore/costs-per-state' 
                        render={ ()=> 
                          <Costs
                            width={this.width}
                            height={this.height} 
                            exploreData={this.exploreData}
                            svgPaths={this.svgPaths}
                            localitiesData={this.localitiesData}
                          /> } 
                      />

                      <Route 
                        exact 
                        path='/explore/youth-prison-locations' 
                        render={ ()=> 
                          <Locations
                            width={this.width}
                            height={this.height} 
                            exploreData={this.exploreData}
                            svgPaths={this.svgPaths}
                            localitiesData={this.localitiesData}
                            facilitiesData={this.facilitiesData}
                          /> }
                      />

                      <Route 
                        exact 
                        path='/explore/:state'
                        render={ ({match})=> 
                          <StateDetail
                            width={this.width}
                            height={this.height}
                            exploreData={this.exploreData}
                            svgPaths={this.svgPaths}
                            localitiesData={this.localitiesData}
                            facilitiesData={this.facilitiesData}
                            match={match}
                            key={match.params.state}
                            windowDimensions={this.props.windowDimensions}
                            history = {this.props.history}
                          /> }
                      />
                  </Switch>
                
              }

          </div>
      );
  }
} //end class

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Explore));