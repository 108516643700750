import React from 'react';

const DropDown = (props) => {
    return (
        <div className="default-css">
            <select value={props.selectedDropdown} onChange={props.onDropdownChange}>
                {
                    props.dropdownValues.map((arr)=> (
                        <option key={arr[0]} value={arr[0]}>
                            {arr[1]}
                        </option>
                    ))
                }
            </select>
        </div>
    );
}

export default DropDown