import jump from "jump.js";

export function slugify(text){
    return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
    .replace(/-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export function unSlugify(text){
    return text.toString().toUpperCase()
    .replace(/-/g, " ")           // Replace - with a space
    .replace(/[^\w -]+/g, '')       // Remove all non-word chars
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleCase(text){
    return text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

export function currency(num, fraction=0){
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        currency: 'USD',
        minimumFractionDigits: fraction
    })
    
    return formatter.format(num)
}

export function nearestK(num){
    return Math.ceil(num/1000);
}

export function getScrollPostion(){
    if (window.pageYOffset !== undefined) {
        return [window.pageXOffset, window.pageYOffset];
    } else {
        var sx, sy, d = document,
            r = d.documentElement,
            b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        return [sx, sy];
    }
}

export function getWindowDimensions(){
    const ww = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth,
            wh = window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight;

    return {width:ww, height:wh}
}

export function fDate(today){
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    
    if (dd < 10) {
      dd = '0' + dd;
    }
    
    if (mm < 10) {
      mm = '0' + mm;
    }
    
    return mm + '-' + dd + '-' + yyyy;
}

export function toTop(){
    jump('body', {
        duration: 300,
        offset: -100
    })
}

export function getPrevRoute(currentRoute, nav){
    if(!currentRoute) {
        console.log('No current route');
        return false;
    };
    if(currentRoute==='watch'){
        return {route:'/'}
    }
    if(typeof currentRoute === 'object'){
        currentRoute = currentRoute.current;
    }
    // console.log(currentRoute);
    let index = false;
    for (var i = 0; i < nav.length; i++) {
        if(nav[i].route===`/${currentRoute}`){
            index = i;
            break;
        }
    }
    // console.log(index);
    return nav[index-1] ? nav[index-1] : false ;
}

export function getNextRoute(currentRoute, nav){
    if(!currentRoute) {
        console.log('No current route');
        return false;
    };
    if(typeof currentRoute === 'object'){
        currentRoute = currentRoute.current;
    }
    // console.log(currentRoute);
    let index = false;
    for (var i = 0; i < nav.length; i++) {
        if(nav[i].route===`/${currentRoute}`){
            index = i;
            break;
        }
    }
    // console.log(index);
    return nav[index+1] ? nav[index+1] : false ;
}

//@not complete
export function handleClick(event){
    if (event.button === 0) {
        event.preventDefault();
        // const path = new URL(event.currentTarget.href).pathname;
        // browserHistory.push(path);
    }
}

export function d(p){
    console.log(p);
}

export function hasHash(h){
    const hash = window.location.hash.substr(1);
    return hash===h;
}