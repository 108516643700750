import React from 'react';

const lstyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    position: "absolute",
    width: "100%"
}
const Loading = ( props ) => {
	return(
		<div className="loading" style={lstyle}>
			Loading...		
		</div>
	)
}


export default Loading;