import React, { Component } from 'react';
import SocialShare from './SocialShare';

import "../assets/scss/lightbox.scss";

class Lightbox extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount = () => {
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextId = nextProps.data ? nextProps.data.id : null,
              prevId = this.props.data ? this.props.data.id : null;
        // console.log(nextId, prevId);
        if(nextId!=prevId){
            return true;
        }
        return false;
    }
    
    componentWillUnmount(){
        // this.props.data.id = null;
    }

    render(){
        const 
            socialURL = `${process.env.REACT_APP_BASE_URL}`,
            defaultExcerpt = "I wanted to share this Media from https://www.nokidsinprison.org/ with you",
            {data} = this.props;

        return (
            <div className="lightbox">
                <div className="lightbox-inner">
                    {data ? 
                        <React.Fragment>
                            <div onClick={this.props.close} className="close">Close</div>
                            {data.video_id ?
                                <div className="video-container">
                                    {data.video_site==='youtube' ? 
                                        <iframe 
                                            src={`https://www.youtube-nocookie.com/embed/${data.video_id}` }
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                            allowFullScreen>
                                        </iframe>
                                        :
                                        <iframe 
                                            src={`https://player.vimeo.com/video/${data.video_id}`} 
                                            frameBorder="0" 
                                            allowFullScreen>
                                        </iframe>
                                    }

                                </div>
                            :
                                <img src={data.og_image} alt={data.post_title} />
                            }
                            <SocialShare 
                                to={`${socialURL}/take-action/share/single/${data.post_name}`} 
                                title={data.post_title}
                                description={data.excerpt ? data.excerpt : defaultExcerpt}
                                twitterText={data.post_title}
                            />
                        </React.Fragment>
                        :
                        <div className="">Loading...</div>
                    }
                </div>
            </div>
        )
    }
}

export default Lightbox;