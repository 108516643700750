import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { geoAlbersUsa} from "d3";
import sortBy from 'lodash/sortBy'
import MapLocations from "./MapLocations"
import DropDown from "./DropDown"
import Text from "./Text"
import pinM from '../../assets/images/pin-male.svg';
import pinF from '../../assets/images/pin-female.svg';
import pinB from '../../assets/images/pin-both.svg';
import Gradient from "./Gradient"
import { toTop } from '../../../helpers/helpers'
import { setCurrentRoute, setHeaderColor, toggleMenuState,
    setMetaDescription, setMetaTitle, setMetaImage } from '../../../store/actions/action';

class Locations extends Component {
    
    state = {
        stateColors: [],
        markers: [],
        mapScale: false,
        mapTranslate: false,
        activeState: false,
        dropdownValues: [],
        selectedDropdown: ''
    };

    onDropdownChange = (e) => {
        const selectedDropdown = e.target.value;
        this.setState({selectedDropdown})
    }

    componentDidMount(){

        this.props._setCurrentRoute({'current':'explore', 'sub':'locations'});
        this.props._setHeaderColor('blue');
        // this.props._openMenu(false);

        this.props._setMetaTitle('Youth Prison Locations');
        this.props._setMetaDescription('Youth Prison Locations');
        this.props._setMetaImage('');

        let dropdownValues = this.props.svgPaths.map((d, i)=>([d.id, d.properties.name]));
        dropdownValues = sortBy(dropdownValues, [o=>o[1]]);
        dropdownValues.unshift(['', 'Lower 48']);

        const markers = this.props.facilitiesData.map( (d, i) => ({
            'id': d.id,
            'lat': d.latitude,
            'long': d.longitude,
            'gender': d.gender,
            'name': d.name,
            'state' : d.locality,
            'closed' : d.year_closed
        }));

        const stateColors = this.props.localitiesData.map((d,i)=>({
            'id' : d.map_id,
            'color' : '#c9cdd4'
        }))

        this.setState({markers, stateColors, dropdownValues});
        toTop();
    }

    
    render(){
        
      if(this.state.stateColors.length===0) return null;
        return (
            <div className="container">
                <div className="map-container map-container-locations">
                    <div className="mobile-only">
                        <Gradient 
                            gradientClass="none"
                            meta={[
                                {html: `<img height="28" width="28" src=${pinF} alt="Pin"/> Girls`, className:"align-center"},
                                {html: `<img height="28" width="28" src=${pinB} alt="Pin"/> Girls & Boys`, className:"align-center"},
                                {html: `<img height="28" width="28" src=${pinM} alt="Pin"/> Boys`, className:"align-center"}
                            ]}
                        />
                    </div>

                    <MapLocations
                        width={ this.props.width } 
                        height={ this.props.height }
                        svgPaths={this.props.svgPaths}
                        markers={this.state.markers}
                        stateColors={this.state.stateColors}
                        selectedDropdown={this.state.selectedDropdown}
                    />
                    
                    <div className="map-detail">
                        <Text
                            h2={this.props.exploreData.page_data.locations.title}
                            html={this.props.exploreData.page_data.locations.content}
                        />

                        <div className="map-meta desktop-only">
                            <div className="default-css">
                                <p className="mb0">Zoom To: </p>
                            </div>
                            <DropDown 
                                selectedDropdown={this.state.selectedDropdown}
                                onDropdownChange={this.onDropdownChange}
                                dropdownValues={this.state.dropdownValues}
                            />

                            <Gradient 
                                gradientClass="none"
                                meta={[
                                    {html: `<img height="28" width="28" src=${pinF} alt="Pin"/> Girls`, className:"align-center"},
                                    {html: `<img height="28" width="28" src=${pinB} alt="Pin"/> Girls & Boys`, className:"align-center"},
                                    {html: `<img height="28" width="28" src=${pinM} alt="Pin"/> Boys`, className:"align-center"}
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
        )
  }
} //end class

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _openMenu : (isOpen) => {
          dispatch( toggleMenuState(isOpen) )
        },
        _setMetaTitle: (param) => {
          dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);