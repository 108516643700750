import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import { setCurrentRoute, setHeaderColor, setMetaDescription, setMetaTitle, setMetaImage } from '../../../store/actions/action';
import { nearestK, titleCase, unSlugify } from '../../../helpers/helpers'
import PieChart from './PieChart';
import StackedBarChart from './StackedBarChart';
import BarChart from './BarChart';
import Cover from '../Cover';
import SocialShare from '../SocialShare';
import svgSprite from '../../assets/images/sprite.svg'
import pinM from '../../assets/images/pin-male.svg';
import pinF from '../../assets/images/pin-female.svg';
import pinB from '../../assets/images/pin-both.svg';
import jump from "jump.js";

class StateDetail extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            stateData: false
        };
        this.stateName = unSlugify( this.props.match.params.state );
        
        this.UsState = this.props.localitiesData.filter((d, key)=>{
            return this.stateName.toUpperCase() === d.name
        })

        this.facilities = this.props.facilitiesData.filter((d, key)=>{
            return this.stateName.toUpperCase() === d.locality
        })

        this.UsState = this.UsState.length ? this.UsState[0] : false
        
        this.totalinJail = parseInt(this.UsState.white_residents) +
            parseInt(this.UsState.black_residents) +
            parseInt(this.UsState.hispanic_residents) +
            parseInt(this.UsState.american_indian_residents) +
            parseInt(this.UsState.asian_residents) +
            parseInt(this.UsState.other_residents);

            // this.blackTimesWhite = Math.round( (parseInt(this.UsState.black_residents)/parseInt(this.UsState.black_percent)) /
            // (parseInt(this.UsState.white_residents)/parseInt(this.UsState.white_percent)) );
            this.blackTimesWhite = (parseFloat(this.UsState.male_black_rate)/parseFloat(this.UsState.male_white_rate)).toFixed(1);

        this.incarcerated = [
            {'race': 'White', 'percentage': this.inJailPercentage('white_residents'), 'column': 'white_residents', 'color':'#3f4e97' },
            {'race': 'Black', 'percentage': this.inJailPercentage('black_residents'), 'column': 'black_residents', 'color':'#4871b8' },
            {'race': 'Latino', 'percentage': this.inJailPercentage('hispanic_residents'), 'column': 'hispanic_residents', 'color':'#000000' },
            {'race': 'Native American', 'percentage': this.inJailPercentage('american_indian_residents'), 'column': 'american_indian_residents', 'color':'#999999' },
            {'race': 'Asian', 'percentage': this.inJailPercentage('asian_residents'), 'column': 'asian_residents', 'color':'#352772' },
            {'race': 'Other', 'percentage': this.inJailPercentage('other_residents'), 'column': 'other_residents', 'color':'#4e85cc' }
        ];

        this.youthPopulation = [
            {'race': 'White', 'percentage': Math.round(this.UsState.white_percent), 'column': 'white_percent', 'color':'#4871b8' },
            {'race': 'Black', 'percentage': Math.round(this.UsState.black_percent), 'column': 'black_percent', 'color':'#3f4e97' },
            {'race': 'Latino', 'percentage': Math.round(this.UsState.hispanic_percent), 'column': 'hispanic_percent', 'color':'#000000' },
            {'race': 'Native American', 'percentage': Math.round(this.UsState.american_indian_percent), 'column': 'american_indian_percent', 'color':'#999999' },
            {'race': 'Asian', 'percentage': Math.round(this.UsState.asian_percent), 'column': 'asian_percent', 'color':'#4e85cc' }
        ];

        this.stackedData = 
            {
                data : [
                    {
                        'group' : `${titleCase(this.UsState.name)}'s Youth Population`,
                        'white': this.UsState.white_percent,
                        'black': this.UsState.black_percent,
                        'hispanic': this.UsState.hispanic_percent,
                        'american_indian': this.UsState.american_indian_percent,
                        'asian': this.UsState.asian_percent,
                    },
                    {
                        'group' : 'Incarcerated Youth Population',
                        'white': this.inJailPercentage('white_residents'),
                        'black': this.inJailPercentage('black_residents'),
                        'hispanic': this.inJailPercentage('hispanic_residents'),
                        'american_indian': this.inJailPercentage('american_indian_residents'),
                        'asian': this.inJailPercentage('asian_residents'),
                        'other': this.inJailPercentage('other_residents')
                    }
                ],
                meta: {
                    "white" : {color: '#e52c5a', label : 'White'}, 
                    "black" : {color: '#352772', label : 'Black'},
                    "hispanic" : {color: '#0fa388', label : 'Latino'},
                    "american_indian" : {color: '#0f5246', label : 'Native American'}, 
                    "asian" : {color: '#4E85CC', label : 'Asian'},
                    "other" : {color: '#999999', label : 'Other'}
                }
            };


        this.maxConfinementCost = Math.max(...this.props.localitiesData.map(o => o.annual_confinement_cost), 0);

        this.multipurpose = {
            "U":"Unknown",
            "N":"No",
            "C":"Committed",
            "D":"Detained",
            "B":"Committed & Detained"
        };
    }

    componentDidMount(){
        this.props._setCurrentRoute({'current': 'explore', 'sub': 'explore-state'});
        this.props._setHeaderColor('white');

        this.props._setMetaTitle(`Explore ${this.stateName}`);
        // this.props._setMetaDescription(data.excerpt);
        this.props._setMetaImage(`${process.env.REACT_APP_BASE_URL}/states/${this.UsState.short_name}.svg`);
        const resourceUrl = process.env.REACT_APP_API_URL + "/youthfirst/state/" + this.props.match.params.state;
        const facilities = this.facilities.map(d=>{
            return `facility[]=${d.id}`
        })
        axios.get(`${resourceUrl}?${facilities.join('&')}`).then(({data}) => {
            if(data.status){
                this.setState({
                    stateData: data
                });

                const url = new URL( window.location.href ),
                        section = url.searchParams.get("section"),
                        facility = url.searchParams.get("facility");
                
                let id = "root";
                if(facility){
                    id = `facility-${facility}`;
                } else if (section){
                    id = section;
                }
                const node = document.getElementById(id);
                if(node){
                    jump(node, {
                        duration: 500,
                        offset: -60,
                        callback: () => {
                        }
                    })
                }
            }
        })
    }

    inJailPercentage = (col) => {
        return ( (parseInt(this.UsState[col])/this.totalinJail)*100 ).toFixed(2);
        // return Math.round((parseInt(this.UsState[col])/this.totalinJail)*100);
    }

    render(){
        
        if(this.state.stateData===false) return <div/>;
        if(this.UsState===false) return '<div className="container">State Not Found</div>';
        
        const tweetCopy = this.props.exploreData.page_data.costs.tweet_copy===undefined ?
                                "In {{short_name}}, African American youth are {{black_rate}}x more likely than white youth to be incarcerated." : 
                                this.props.exploreData.page_data.costs.tweet_copy;
        const stateName = titleCase(this.UsState.name),
                gender = { 'M':'Boys', 'F':'Girls', 'B':'Boys & Girls' },
                resourcesSection = this.state.stateData.resources.length>0,
                parentsPay = this.UsState.parents_charged==="M" 
                    ? `In ${stateName}, the state requires family to pay some of the cost of confinement.` 
                    : `In ${stateName}, the state may require family to pay some of the cost of confinement.`,
                og = this.state.stateData.og,
                socialURL = `${process.env.REACT_APP_BASE_URL}/explore/${this.props.match.params.state}`,
                twitterCostText = `In ${this.UsState.short_name} it can cost $${this.UsState.annual_confinement_cost?`${nearestK(this.UsState.annual_confinement_cost)}K`:'NaN'}/year to imprison a child, but only $${nearestK(this.UsState.public_education_cost)}K/year for public education.`,
                // twitterRaceText = ` In ${this.UsState.short_name}, African American youth are ${this.blackTimesWhite}x more likely than white youth to be incarcerated.`;
                twitterRaceText = tweetCopy
                                    .replaceAll("{{short_name}}", this.UsState.short_name)
                                    .replaceAll("{{black_rate}}", this.blackTimesWhite);
        
        const youth_prison_inventory = this.state.stateData.youth_prison_inventory.length;
        // console.log( 'cd dev here..' );
        // console.log( youth_prison_inventory );
        return(
            <div className="explore-state-container">
                <Cover 
                    title={`Youth Incarceration <br/> in ${stateName}`}
                    cover=""
                    content={`<img src="/states/${this.UsState.short_name}.svg" alt=${stateName}/>`}
                    contentClasses="content-left-container"
                />

                <section id="race-interactive" className="explore-section explore-racial">
                    <div className="container">
                        <h2 className="title">RACIAL DISPARITIES</h2>
                        <div className="explore-section-inner">
                            <div className="explore-section-inner-inner">
                                <h3 className="section-desc">
                                    In {stateName} <span className="blue-color">African American youth are {this.blackTimesWhite}x more likely </span> 
                                    than white youth to be incarcerated. 
                                </h3>

                                <div className="stackedbar-container">
                                    <StackedBarChart
                                        breakpoint={this.props.windowDimensions.width>920 ? 'desktop' : 'mobile'}
                                        stackedData={this.stackedData}
                                    />
                                </div>

                                {/* <div className="pie-container">
                                    <div className="pie">
                                        <PieChart
                                            size={this.props.windowDimensions.width>920 ? 272 : 150}
                                            data={this.incarcerated}
                                        />
                                        <p>Incarcerated Youth Population</p>
                                    </div>

                                    <div className="pie">
                                        <PieChart
                                            size={this.props.windowDimensions.width>920 ? 167 : 120}
                                            data={this.youthPopulation}
                                        />
                                        <p>Compared to {stateName}'s Youth Population</p>
                                    </div>
                                </div>
                                 */}
                            </div>
                        </div>
                    </div>
                    <SocialShare 
                        to={`${socialURL}?section=race-interactive`} 
                        title={og.race[0]}
                        description={og.race[1]}
                        twitterText={twitterRaceText}
                    />
                </section>

                <section id="cost-interactive" className="explore-section explore-cost">
                    <div className="container">
                        <h2 className="title">COST TO TAXPAYERS</h2>
                        <div className="explore-section-inner">
                            <div className="explore-section-inner-inner">
                                <h3 className="section-desc">
                                In {stateName} <span className="green-color">it can cost <span className="sup">$</span>{this.UsState.annual_confinement_cost?`${nearestK(this.UsState.annual_confinement_cost)}K`:'NaN'}/year to imprison a child,&nbsp;</span> 
                                    but only <span className="sup">$</span>{nearestK(this.UsState.public_education_cost)}K/year for public education.
                                </h3>

                                <div className="barchart-container">
                                    <BarChart 
                                        confinementCost={this.UsState.annual_confinement_cost} 
                                        educationCost={this.UsState.public_education_cost}
                                        maxConfinementCost={this.maxConfinementCost}
                                    />
                                    <div className="barchart-meta">
                                        <div className="bar-disclaimer">
                                            This bar chart juxtaposes the average cost to incarcerate one
                                            child for one year in this state alongside the average annual
                                            cost of educating that child.
                                        </div>
                                        <div className="parents-pay">
                                            <span className="icon icon-pay">
                                                <svg>
                                                    <use xlinkHref={`${svgSprite}#pay`} />
                                                </svg>
                                            </span>
                                            {parentsPay}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <SocialShare 
                        to={`${socialURL}?section=cost-interactive`} 
                        title={og.cost[0]}
                        description={og.cost[1]}
                        twitterText={twitterCostText}
                    />
                </section>
                
                {/* { youth_prison_inventory && 
                    <section id="youth-facilities" className="explore-section explore-fac">
                        <div className="container">
                            <h2 className="title">YOUTH FACILITIES</h2>
                            <div className="explore-section-inner">
                                
                                <div>
                                    {this.state.stateData.youth_prison_inventory.map((inventory, i)=>(
                                        <div key={i} className="facility youth-facility">
                                            <h3>{inventory.section.label}</h3>
                                            <p>{inventory.detail}</p>  
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>

                } */}

                <section id="facilities" className="explore-section explore-locations">
                    <div className="container">
                        <h2 className="title">FACILITY LOCATIONS</h2>
                        { (youth_prison_inventory || this.state.stateData.youth_facility_introduction) &&
                            <div className="explore-section-inner youth-facilities-inventory-container">
                                <div className="explore-section-inner-inner">
                                    { this.state.stateData.youth_facility_introduction && 
                                        <div className="default-css">
                                            <h3>Youth facilities in {stateName}</h3>
                                            
                                                <p style={{maxWidth:'900px'}}>{this.state.stateData.youth_facility_introduction}</p>
                                        </div>
                                    }
                                    { youth_prison_inventory &&
                                        <div>
                                            {this.state.stateData.youth_prison_inventory.map((inventory, i)=>(
                                                <div key={i} className={`${inventory.section.value} facility youth-facility`}>
                                                    <div class='fcties-title-container'>  
                                                        <span className="icon facility-icon">
                                                            <svg><use xlinkHref={`${svgSprite}#${inventory.section.value}`} /></svg>
                                                        </span>
                                                        <h3>{inventory.section.label}</h3>
                                                    </div>
                                                    <div className="default-css">
                                                        <p>{inventory.detail}</p>
                                                    </div>  
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    
                        <div className="explore-section-inner">
                            <div className="explore-section-inner-inner">
                                <div className="default-css">
                                    <h3>Youth prison facilities in {stateName}</h3>
                                    <p style={{maxWidth:'900px'}}>
                                    Facility information was collected from state juvenile justice, 
                                    youth services and correctional departments,
                                    websites, and other public sources including state agency annual reports, 
                                    state fact sheets, departmental budgets and planning documents, 
                                    and Prison Rape Elimination Act (PREA) audits.
                                    </p>
                                </div>
                                {this.facilities.map((d)=>{
                                    
                                    let pin = pinM;
                                    if(d.gender==='F') pin = pinF;
                                    else if(d.gender==='B') pin = pinB;

                                    const twitterText = `Did you know there's a youth prison designed to incarcerate ${d.total_design_capacity} children in ${d.city}, ${d.state}?`;

                                    let bounds = new this.props.google.maps.LatLngBounds();
                                    bounds.extend({ lat: +d.latitude, lng: +d.longitude });
                                    
                                    var offset = 0.001;     
                                    var center = bounds.getCenter();                            
                                    bounds.extend(new this.props.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
                                    bounds.extend(new this.props.google.maps.LatLng(center.lat() - offset, center.lng() - offset));
                                    
                                    return(
                                        <div id={`facility-${d.id}`} className="facility" key={d.id}>
                                            <h3>{d.name}</h3>
                                            <address>{d.address}, {d.city}, {d.state} {d.zip} &nbsp;&nbsp;|&nbsp;&nbsp; est. {d.year_established}</address>
                                            
                                            <div className="facility-detail">
                                                <div className="facility-photo">
                                                    <div style={{height:'100%',position:'relative'}}>
                                                        {d.year_closed &&
                                                            <div>
                                                                <div class="facility-closed"></div>
                                                                <div class="facility-closed-text">CLOSED</div>
                                                            </div>
                                                        }
                                                        <Map 
                                                            google={this.props.google} 
                                                            initialCenter = {
                                                                {
                                                                    lat: d.latitude,
                                                                    lng: d.longitude
                                                                }
                                                            }
                                                            mapType="satellite"
                                                            bounds={bounds}
                                                            zoom={10}
                                                        >
                                                            <Marker 
                                                                icon={{
                                                                    url : pin,
                                                                    scaledSize : new this.props.google.maps.Size(32,32)
                                                                }}
                                                            />
                                                        </Map>
                                                    </div>
                                                </div>
                                                <div className="facility-meta">
                                                    <p>
                                                        {d.operator} <br />
                                                        {d.security_classification} <br />
                                                        Design Capacity   <span className="blue-color">{d.total_design_capacity} </span>
                                                        {d.num_beds_female && <span className="blue-color">{`(${d.num_beds_female} girls)`}</span> } <br />
                                                        Operating Capacity  <span className="blue-color">{d.operating_capacity}</span>
                                                    </p>

                                                    
                                                    <p>
                                                        <span className="icon-boy-girl">
                                                            <svg viewBox="0 0 38.58 28.93">
                                                                <use xlinkHref={`${svgSprite}#boy-girl`} />
                                                            </svg>
                                                        </span>
                                                        <span>{gender[d.gender]} | Ages {d.minimum_age}-{d.maximum_age} </span>
                                                    </p>

                                                    <p>
                                                        <span className="icon-committed">
                                                            <svg viewBox="0 0 23.12 23.12">
                                                                <use xlinkHref={`${svgSprite}#committed`} />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            {this.multipurpose[d.multipurpose_facility]}
                                                        </span>
                                                    </p>
                                                    
                                                    {d.sexual_abuse==='Y' &&
                                                        <p>
                                                            <span className="icon-victim">
                                                                <svg viewBox="0 0 5.83 19.38">
                                                                    <use xlinkHref={`${svgSprite}#victim`} />
                                                                </svg>
                                                            </span>
                                                            <span>Sexual Victimization</span>
                                                        </p>
                                                    }
                                                    
                                                    {d.physical_abuse==='Y' &&
                                                        <p>
                                                            <span className="icon-abuse">
                                                                <svg viewBox="0 0 13.53 22.8">
                                                                    <use xlinkHref={`${svgSprite}#abuse`} />
                                                                </svg>
                                                            </span>
                                                            <span>Physical Abuse & Neglect</span>
                                                        </p>
                                                    }
                                                    
                                                    <SocialShare 
                                                        className="inline"
                                                        to={`${socialURL}?section=facilities&facility=${d.id}`} 
                                                        title={og.facility[d.id][0]}
                                                        description={og.facility[d.id][1]}
                                                        twitterText={twitterText}
                                                        label="Share this facility"
                                                    />
                                                </div>
                            

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                {resourcesSection &&
                    <section id="more-resources" className="explore-section explore-resources">
                        <div className="container">
                            <h2 className="title">MORE RESOURCES</h2>
                            <div className="explore-section-inner">
                            {this.state.stateData.resources.map((resource, i)=>(
                                <p key={i} className="resource-link">
                                    <a href={resource.link}>
                                        <span className={`icon icon-${resource.type}`}>
                                            <svg><use xlinkHref={`${svgSprite}#${resource.type}-icon`} /></svg>
                                        </span>
                                        <span>{resource.title}</span>
                                    </a>
                                </p>
                            ))}
                            </div>
                        </div>
                    </section>
                }
                
                <section className="explore-section explore-back">
                    <div className="container">
                        <a className="back-to-explore" href="/explore" onClick={e=>{
                            e.preventDefault();
                            if(this.props.history.action==='POP'){
                                this.props.history.push('/explore');
                            }else{
                                this.props.history.goBack();
                            }
                        }}>
                            <span className="icon icon-usa">
                                <svg><use xlinkHref={`${svgSprite}#icon-usa`} /></svg>
                            </span>
                            <strong>BACK TO EXPLORE MAP</strong>
                        </a>
                    </div>
                </section>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
            dispatch(setHeaderColor(color))
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}

const LoadingContainer = (props) => ( <div></div> )

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey : process.env.REACT_APP_GOOGLEMAPS_API,
    LoadingContainer : LoadingContainer,
    maxZoom: 10
})(StateDetail))
