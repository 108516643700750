import React, { Component } from "react";
// import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import axios from "axios";
import { setCurrentRoute, setHeaderColor, setMetaDescription, setMetaTitle, setMetaImage } from '../store/actions/action';
import Cover from './components/CoverD';
import ContactUsForm from './components/forms/ContactUsForm';

// import "./styles.css";

class ContactUs extends Component {


    state = {
        content : false,
    };
    
    componentDidMount(){
        const postSlug = 'contact-us';
        this.props._setCurrentRoute('contact-us');
        // this.props._setHeaderColor('white');
        const postUrl = process.env.REACT_APP_API_URL + "/youthfirst/post/" + postSlug
        axios.get(postUrl).then(({data}) => {
            // console.log(data);
            this.setState({ content : data });
            // this.content = data;

            this.props._setMetaTitle(data.post_title);
            this.props._setMetaDescription(data.excerpt);
            this.props._setMetaImage(data.cover);
            window.scrollTo(0, 0);
        })
    }
  render() {
      if(!this.state.content.form_id) return <div/>;
    return (
        <div className="">
            <Cover 
                title={this.state.content.post_title} 
                cover={this.state.content.cover} 
                content={this.state.content.excerpt}
            />
            <div className="container">
                <div className="default-post default-css">
                    <div dangerouslySetInnerHTML={{__html: this.state.content.page_content}} />
                    <ContactUsForm formId={this.state.content.form_id} />
                </div>
            </div>
        </div>
    );
  }
}



const mapStateToProps = (state) => {
    return {
    // nav_menus : state.rootReducer.nav_menu,
    // hp_counter:state.rootReducer.hp_counter,
    // home_data: state.rootReducer.home_data
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        _setCurrentRoute: (route) => {
        dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
// console.log(App);
// export default ContactUs;
// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
