
import * as React from "react";
import { currency } from '../../../helpers/helpers'


const BarChart = (props) => {

    let maxBarSize      = 190,
        confinementCost = parseInt(props.confinementCost) || 0,
        educationCost   = parseInt(props.educationCost) || 0,
        maxConfinementCost = props.maxConfinementCost,
        confinementBar  = confinementCost>0 ? (maxBarSize * confinementCost/maxConfinementCost) : (maxBarSize * 100000/maxConfinementCost),
        educationBar    = maxBarSize * educationCost/maxConfinementCost;

    return (
        <div className="bar-chart">
            <div className="bar-container confinement-bar">
                <div className="cost"><span className="sup">$</span>{confinementCost===0?'NaN':currency(confinementCost)}</div>
                <div className="bar" style={{'height':confinementBar}}></div>
                <div className="description">Annual cost of most expensive confinement option per child</div>
            </div>
            <div className="bar-container education-bar">
                <div className="cost"><span className="sup">$</span>{educationCost===0?'NaN':currency(educationCost)}</div>
                <div className="bar" style={{'height':educationBar}}></div>
                <div className="description">Annual cost of public school education per student</div>
            </div>
               
        </div>
    );
}
export default BarChart;