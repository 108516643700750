import React, { Component } from 'react';
import { connect } from 'react-redux';
import "../assets/scss/cover.scss";
import { setCurrentRoute, setHeaderColor } from '../../store/actions/action';

class Cover extends Component {
	componentDidMount(){
		if( !this.props.cover ){
			this.props._setHeaderColor('blue');
		}
	}
	render(){
		const cover_type = ( this.props.cover ? 'bg-image' : 'solid' );

		const style = this.props.cover ? {
			backgroundImage : `url(${this.props.cover})`
		} : {};
		
		return (
			<div className="cover-container">
				<div className={`cover full-background ${this.props.contentClasses || "content-center-container" } ${cover_type}`} style={style}>
					<div className="cover-content">
						<div className="container">
							<h1 className="title" dangerouslySetInnerHTML={{ __html: this.props.title }} />
							{this.props.content &&
								<div className="desc" dangerouslySetInnerHTML={{ __html: this.props.content }} />
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
    return {
    
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    _setCurrentRoute: (route) => {
      dispatch(setCurrentRoute(route))
    },
    _setHeaderColor: (color) => {
      dispatch(setHeaderColor(color))
    }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Cover);