import React from 'react';

const Text = (props) => {
    return (
        <div className="map-text">
            <div className="default-css">
                <h3>{props.h2}</h3>
                <div dangerouslySetInnerHTML={{__html: props.html}}></div>
            </div>
        </div>
    );
}

export default Text