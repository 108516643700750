import React, { Component } from 'react';

class Video extends Component {

    constructor(props){
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount = () => {
        if(this.props.playing){
            // this.videoRef.current.play();
            this.videoRef.current.setAttribute('controls', false);
        }
    }

    componentDidUpdate(prevProps) {

        if(this.props.playing){
        
            this.videoRef.current.play();
            this.videoRef.current.setAttribute('controls', false);
            // console.log(this.videoRef.current)
        
        } else {
        
            this.videoRef.current.pause();
            this.videoRef.current.setAttribute('controls', true);
            // console.log(2)
        
        }

    }
    
    render(){
        
        return (
            <video style={{"WebkitTransformStyle": "preserve-3d"}} className={this.props.classProp} ref={this.videoRef} poster={this.props.poster} loop muted playsInline>
                <source src={this.props.src} type={this.props.type} />
            </video>
        )
    }
}

export default Video;