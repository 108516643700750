import {
	HOME_SLIDE_COUNTER,
	FIRST_SLIDE_COUNTER,
	CURRENT_ROUTE,
	CURRENT_SUB_ROUTE,
	IS_MENU_OPEN,
	HEADER_COLOR,
	NAV_MENU,
	IS_LOADING,
	META_TITLE, META_URL, META_DESCRIPTION, META_IMAGE 
} from '../actions/types'

const init_states ={
	homeSlideCounter : 0,
	firstSlideCounter: 1,
	currentRoute : "",
	nextRoute: "",
	prevRoute: "",
	isMenuOpen : false,
	headerColor: 'white',
	navMenu: false,
	metaTitle: '',
	metaDescription: '',
	metaUrl: '',
	metaImage: '',
	isLoading: false
}

export default (state = init_states , action )=>{
	switch (action.type) {
		case NAV_MENU:
			return({
				...state,
				navMenu: action.payload,
			})
		case IS_LOADING:
			return({
				...state,
				isLoading: action.payload,
			})
		case HOME_SLIDE_COUNTER:
			return({
				...state,
				homeSlideCounter: action.payload,
			})
		case FIRST_SLIDE_COUNTER:
			return({
				...state,
				firstSlideCounter: action.payload,
			})
		case CURRENT_ROUTE:
			return({
				...state,
				currentRoute: action.payload
			})
		case CURRENT_SUB_ROUTE:
			return({
				...state,
				currentRoute: action.payload
			})
		case HEADER_COLOR:
			return({
				...state,
				headerColor: action.payload
			})
		case IS_MENU_OPEN:
			return ({
				...state,
				isMenuOpen: action.payload
			})	
		case META_TITLE:
			return ({
				...state,
				metaTitle: action.payload
			})	
		case META_URL:
			return ({
				...state,
				metaUrl: action.payload
			})
		case META_DESCRIPTION:
			return ({
				...state,
				metaDescription: action.payload
			})
		case META_IMAGE:
			return ({
				...state,
				metaImage: action.payload
			})
		default:
			return state;
	}
}


// export default reducer;