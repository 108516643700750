import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import Cover from './components/Cover';
import Lightbox from './components/Lightbox';
import PostsGridSocial from './components/PostsGridSocial';
import { setCurrentRoute, setHeaderColor, toggleMenuState, 
    setMetaDescription, setMetaTitle, setMetaImage } from '../store/actions/action';
import Pagination from "react-js-pagination";
import "./assets/scss/sharePosts.scss";

class PostShare extends Component {

    state = {
        loading : true,
        paged : this.props.match.params.pageParam || false,
        posts : [],
        found_posts: 0,
        filters: {},
        activePost: false,
        single: false
    };
    content = false;
    postType = false;
    posts_per_page = 10;

    componentDidMount(){
        // console.log('PostShare componentDidMount')
        this.postType = "share";
        this.props._setCurrentRoute(this.postType);
        this.props._setHeaderColor('blue');
        
        const PageUrl = process.env.REACT_APP_API_URL + "/youthfirst/page/social-media-posts"
		axios.get(PageUrl).then(({data}) => {
            this.content = data;
            this.setState({loading:false});

            this.props._setMetaTitle(data.page_title);
            this.props._setMetaDescription(data.excerpt);
            this.props._setMetaImage(data.cover);         
        })

        const paged = this.state.paged > 1 ? this.state.paged : 1;
        localStorage.setItem('pageNumber', paged);
        this.setPosts();
        if(this.props.match.params.slug){
            const single = this.props.match.params.slug;
            this.setState({single}, ()=>{
                this.setActivePost()
            })
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.match.params.slug, prevProps.match.params.slug);
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            const single = this.props.match.params.slug;
            this.setState({single}, ()=>{
                this.setActivePost();
            })
        }
    }

    setPosts = (scrollTo=0) => {
        this.setState({posts:[]}); //if set to false, "No posts found". if set to [] then "loading..."

        const paged = this.state.paged > 1 ? this.state.paged : 1,
            posts_per_page = this.posts_per_page,
            {filters} = this.state;

        let PageUrl = `${process.env.REACT_APP_API_URL}/youthfirst/getSocialMediaPosts?paged=${paged}&posts_per_page=${posts_per_page}`;
        
        Object.keys(filters).map((key) => {
            PageUrl += `&${key}=${filters[key]}`;
        });
    
        axios.get(PageUrl).then(({data}) => {
            this.setState({posts:data.posts, found_posts:data.found_posts, activePage:paged});
            window.scrollTo({behavior: 'smooth', top: scrollTo});
        });
    }

    setActivePost = () => {
        // console.log('setActivePost called')
        const single = this.state.single;
        if(single){
            const postUrl = process.env.REACT_APP_API_URL + "/youthfirst/post/" + single
            axios.get(postUrl).then(({data}) => {
                this.props._setMetaTitle(data.post_title);
                this.props._setMetaDescription(data.excerpt);
                this.props._setMetaImage(data.cover);
                this.setState({activePost:data});
                // console.log(data.video_id);
            })
        }
        else{
            this.setState({activePost:null, single:null});
        }

    }

    handlePageChange = (pageNumber) => {
        this.setState({paged: pageNumber}, ()=>{
            // this.props.history.push(`/take-action/share/page/${pageNumber}`)
            window.history.pushState(`page-${pageNumber}`, '', `/take-action/share/page/${pageNumber}`);
            const el = document.querySelector('.share-filters');
            this.setPosts(el.offsetTop-60);
            this.setState({activePage:pageNumber});
            localStorage.setItem('pageNumber', pageNumber);
        });
    }
    
    filterPosts = (event) => {
        let {filters} = this.state;
        filters[event.target.name] = event.target.value;
        const paged = 1;
        this.setState({filters, paged}, ()=>{
            const el = document.querySelector('.share-filters');
            this.setPosts(el.offsetTop-60);
            window.history.pushState(`take-action-share`, '', `/take-action/share`);
            // this.props.history.push(`/take-action/share`);

        });
    }

    closeLightbox = () => {
        this.setState({single:false, activePost:false}, ()=>{
            this.setActivePost();
            // window.history.pushState(`take-action-share`, '', `/take-action/share`);
            const pageNumber = localStorage.getItem('pageNumber');
            if(pageNumber>1)
                this.props.history.push(`/take-action/share/page/${pageNumber}`);
            else
                this.props.history.push(`/take-action/share`);
        });
    }

    render() {
        if(this.state.loading) return null;
        const filters = this.content.page_data.filters;

        return (
            <div>
                
                {/*this.state.activePost && 
                    <Lightbox data={this.state.activePost} close={this.closeLightbox}/>
                */}


                {<Route 
                    exact 
                    path='/take-action/share/single/:slug' 
                    render={()=><Lightbox data={this.state.activePost} close={this.closeLightbox}/>}
                />}

                <div className={`${this.postType}-posts`}>
                    
                    <Cover 
                        title={this.content.page_title} 
                        cover={this.content.cover} 
                        content={this.content.excerpt}
                    />

                    {this.content.page_content &&
                        <div className="container">
                            <div className="default-css" dangerouslySetInnerHTML={{__html: this.content.page_content}} />
                        </div>
                    }

                    <div className="masonry-container">

                        <div className="share-filters">
                            {Object.keys(filters).map((key, i)=>{
                                return( 
                                    <div key={key}>
                                        <label htmlFor={`${key}-${i}`}>{filters[key]['label']}</label>
                                        <select 
                                            id={`${key}-${i}`} 
                                            name={key} 
                                            value={this.state.filters[key] ? this.state.filters[key] : ''} 
                                            onChange={this.filterPosts}
                                        >
                                            <option value="">All</option>
                                            {filters[key].terms.map((t)=>{
                                                return <option key={t.term_id} value={t.term_id}>{t.name}</option>
                                            })}
                                        </select>
                                    </div> 
                                )
                            })}
                        </div>
                    </div>

                    <PostsGridSocial 
                        posts={this.state.posts}
                        socialShare={true}
                    />

                    {this.state.found_posts > this.posts_per_page &&
                        <div className="masonry-container">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.posts_per_page}
                                totalItemsCount={this.state.found_posts}
                                pageRangeDisplayed={99}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    }

                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _openMenu : (isOpen) => {
          dispatch( toggleMenuState(isOpen) )
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(PostShare);