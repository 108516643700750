import React, { Component } from 'react';
import { connect } from 'react-redux';
import { geoAlbersUsa, scaleLinear } from "d3";
import Map from "./Map"
import DropDown from "./DropDown"
import Text from "./Text"
import Gradient from "./Gradient"
import { toTop } from '../../../helpers/helpers'
import { setCurrentRoute, setHeaderColor, toggleMenuState,
    setMetaDescription, setMetaTitle, setMetaImage } from '../../../store/actions/action';

class Racial extends Component {
    
    state = {
        stateColors: [],
    };

    colorMap = scaleLinear()
    .domain([1, 10])
    .range(["#56B5FD", "#2D0051"]);

    dropdownValues = [
        // ["male_minority_rate", "White boys : All boys of color"],
        ["male_black_rate", "White Youth : Black Youth"],
        ["male_hispanic_rate", "White Youth : Latinx Youth"],
        ["male_american_indian_rate", "White Youth : Native Youth"],
        ["male_asian_rate", "White Youth : Asian Youth "],
        // ["female_minority_rate", "White girls : All girls of color"],
        // ["female_black_rate", "White girls : Black girls"],
        // ["female_hispanic_rate", "White girls : Latino girls"],
        // ["female_american_indian_rate", "White girls : Native American girls"],
        // ["female_asian_rate", "White girls : Asian girls "]
    ];

    selectedDropdown = 'male_black_rate';

    onDropdownChange = (e) => {
        const race = e.target.value;
        this.selectedDropdown = race;
        let baseRace = 'male_white_rate';
        if (race.substring(0, 2) === "fe") {
            baseRace = 'female_white_rate';
        }
        let stateColors = [];
        stateColors = this.props.localitiesData.map((d,i)=>({
            'id' : d.map_id,
            'color' : this.colorMap(+d[race]/+d[baseRace]),
            'rate' : +d[race]/+d[baseRace]
        }))
        this.setState({stateColors, currentRace:race});
    }

    componentDidMount(){

        this.props._setCurrentRoute({'current':'explore', 'sub':'racial'});
        this.props._setHeaderColor('blue');
        // this.props._openMenu(false);

        this.props._setMetaTitle('Racial Disparity of Incarcerated Youth');
        this.props._setMetaDescription('Racial Disparity of Incarcerated Youth');
        this.props._setMetaImage('');

        let stateColors = this.props.localitiesData.map((d,i)=>({
            'id' : d.map_id,
            'color' : this.colorMap(+d.male_black_rate/+d.male_white_rate),
            'rate' : +d.male_black_rate/+d.male_white_rate
        }))
        this.setState({stateColors});
        toTop();
    }

    projection() {
        return geoAlbersUsa()
          .scale(this.props.width)
          .translate([ this.props.width / 2, this.props.height / 2 ]);
    }

    render(){
        
        if(this.state.stateColors.length===0) return null;
        return (
            <div className="container">
                <div className="map-container racial-map-container">

                    <div className="mobile-only">
                        <strong>Select Race/Gender</strong>
                        <DropDown 
                            selectedDropdown={this.selectedDropdown}
                            onDropdownChange={this.onDropdownChange}
                            dropdownValues={this.dropdownValues}
                        />

                        <Gradient 
                            meta={[
                                '1 : 1<br/> or less',
                                '1 : 5',
                                {
                                    html:'1 : 10<br/> or more',
                                    className: 'align-right'
                                }
                            ]}
                        />
                    </div>
                    <Map
                        id="race-interactive"
                        width={ this.props.width } 
                        height={ this.props.height }
                        svgPaths={this.props.svgPaths}
                        markers={[]}
                        stateColors={this.state.stateColors}
                    />

                    <div className="map-detail">
                        <Text
                            h2={this.props.exploreData.page_data.racial.title}
                            html={this.props.exploreData.page_data.racial.content}
                        />
                        <div className="map-meta desktop-only">
                            <DropDown 
                                selectedDropdown={this.selectedDropdown}
                                onDropdownChange={this.onDropdownChange}
                                dropdownValues={this.dropdownValues}
                            />

                            <Gradient 
                                meta={[
                                    '1 : 1<br/> or less',
                                    '1 : 5',
                                    {
                                        html:'1 : 10<br/> or more',
                                        className: 'align-right'
                                    }
                                ]}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
  }
} //end class


const mapStateToProps = (state) => {
	return {
        // _homeSlideCounter : state.rootReducer.homeSlideCounter
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _openMenu : (isOpen) => {
          dispatch( toggleMenuState(isOpen) )
        },
        _setMetaTitle: (param) => {
          dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(Racial);