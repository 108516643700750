import React from 'react';
import { geoAlbersUsa, geoPath } from "d3";
import { withRouter } from 'react-router-dom';
import { slugify } from '../../../helpers/helpers'


const projection = (props) => {
    return geoAlbersUsa()
      .scale(props.width+300)
      .translate([ props.width / 2, props.height / 2 ]);
}

const handlePathClick = (props, name) => {
    props.history.push(`${slugify(name)}/?section=${props.id}`);
}

const Map = (props) => {
    return (
        <svg width={ props.width } height={ props.height } viewBox={`0 0 ${props.width} ${props.height}`}>
            <g className="states">
                {
                    props.svgPaths.map((d,i) => {
                        const stateColor = props.stateColors.filter(usState=>usState.id===d.id);
                        return (<g key={ `path-${ i }` } data-rate={stateColor[0].rate ? stateColor[0].rate : ''}><path
                            d={ geoPath().projection(projection(props))(d) }
                            id={d.id}
                            fill={ stateColor[0].color }
                            className="state"
                            stroke="#FFFFFF"
                            strokeWidth={ 1 }
                            onClick={()=>handlePathClick(props, d.properties.name)}
                        /><title>{d.properties.name}</title></g>);
                    })
                }
            </g>
        </svg>
    );
}

export default withRouter(Map);