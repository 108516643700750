import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import isDate from 'lodash/isDate';
import isEqual from 'lodash/isEqual';
import jump from "jump.js";
import BlogSidebar from  './components/BlogSidebar';
import BlogPosts from  './components/BlogPosts';
import Cover from './components/Cover';
import './assets/scss/Blog.scss';
import { setCurrentRoute, setHeaderColor, setMetaTitle } from '../store/actions/action';
class Blog extends Component {
	
	state = {
        blogData : false,
        blogPosts : false,
        paging: ''
    }
    componentDidMount(){
        this.props._setCurrentRoute('blog');
        this.props._setHeaderColor('blue');
        this.props._setMetaTitle("Blog - Youth First");
        // this.props._setMetaDescription();

        this.setblogData();
        this.setPosts();
        window.scrollTo(0, 0);
    }
    
    setblogData = () => {
        const blogUrl =  process.env.REACT_APP_API_URL + "/youthfirst/blog/";
        axios.get(blogUrl).then(({data}) => {
            this.setState({
                blogData : data
            });
        })
    }

    setPosts = () => {
        this.setState({
            blogPosts : false
        });
        let blogUrl =  process.env.REACT_APP_API_URL + "/wp/v2/posts?1=1",
            catUrl = '';
        const filter = this.props.match.params.filter || false,
              filterParam = this.props.match.params.filterParam || false;
        let page = this.props.match.params.page || false,
            pageParam = this.props.match.params.pageParam || false;
        let pagingUrl = '/about-us/news/';
        if(filter && filterParam){
            if(filter==='category'){
                
                pagingUrl += `${filter}/${filterParam}/`;
                catUrl = process.env.REACT_APP_API_URL + `/wp/v2/categories?slug=${filterParam}`;
                
            } else if (filter==='date'){
                pagingUrl += `${filter}/${filterParam}/`;
                const dateParams = filterParam.split("-").map(d=>parseInt(d)),
                    y  = parseInt(dateParams[0]);
                
                let m =  dateParams[1]===undefined ? false : parseInt(dateParams[1]),
                    afterDate = false,
                    beforeDate = false;
                
                if(m && m >=1 && m<=12){
                    afterDate = new Date(y, m-1, 1);
                    beforeDate = new Date(y, m, 0, 23, 59, 59, 999);
                } else {
                    afterDate = new Date(y, 0, 1);
                    beforeDate = new Date(y, 11, 0, 23, 59, 59, 999);
                }

                if(!isDate(afterDate) || !isDate(beforeDate)){
                    afterDate = new Date(70);
                    beforeDate = new Date(70);
                }
            
                blogUrl += `&before=${beforeDate.toISOString()}&after=${afterDate.toISOString()}`;

            } else if (filter==='page'){
            
                if(!page && !pageParam){
                    page = filter; 
                    pageParam = filterParam;
                }
            
            }

            if(page && pageParam){
                blogUrl += `&page=${pageParam}`;
            }

        }

        if(catUrl){
            axios
            .get(catUrl)
            .then(({data}) => {
                blogUrl += `&categories=${data[0].id}`;
                axios
                .get(blogUrl)
                .then(({data, headers}) => {
                    
                    this.setState({
                        blogPosts : data,
                        paging:this.getPaging(headers["x-wp-totalpages"], pagingUrl)
                    });
                })
                .catch(error=>{
                    this.setState({ blogPosts : -1, paging: '' });
                })
            }).catch(error=>{
                this.setState({ blogPosts : -1, paging: '' });
            })
        } else {
            axios
            .get(blogUrl)
            .then(({data, headers}) => {
                // console.log(data)
                // data.querySelectorAll('.readmore').map(a=>{
                //     console.log(a);
                // });
                this.setState({ 
                    blogPosts : data, 
                    paging:this.getPaging(headers["x-wp-totalpages"], pagingUrl) 
                });
            })
            .catch(error=>{
                this.setState({ blogPosts : -1, paging: '' });
            })
        }
    }

    getPaging = (totalPages=1, base='/about-us/news/') => {
        let paging = [];
        if(totalPages>1){
            for(let i=1;i<=totalPages;i++){
                paging.push(<NavLink activeClassName="noborder" to={`${base}page/${i}`} key={`page-${i}`}>{i}</NavLink>)
            }
            paging = <div className="paging">{paging}</div>
        }
        return paging;
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.match.params, prevProps.match.params)) {
            this.setPosts();
            const node = document.querySelector(".sidebars-container");
            jump(node, {
                duration: 500,
                offset: -100,
                callback: () => {
                }
            })
        }
    }
    render() {
        return (
            <div>
               <div className="site-blog">
                    <Cover 
                        title = {this.state.blogData.post_title}
                        cover = {this.state.blogData.cover}
                        content = {this.state.blogData.excerpt}
                    />
                    <div className="container">
                        <div className="sidebars-container default-css">
                   		   <BlogPosts posts={this.state.blogPosts} paging={this.state.paging} history={this.props.history} />
                           <BlogSidebar content={this.state.blogData.sidebar} />
                        </div>
                    </div>
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    _setCurrentRoute: (route) => {
      dispatch(setCurrentRoute(route))
    },
    _setHeaderColor: (color) => {
      dispatch(setHeaderColor(color))
    },
    _setMetaTitle: (param) => {
        dispatch(setMetaTitle(param))
    },
    // _setMetaDescription: (param) => {
    //     dispatch(setMetaDescription(param))
    // },
    // _setMetaImage: (param) => {
    //     dispatch(setMetaImage(param))
    // }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
// export default Blog