import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { setCurrentRoute, setHeaderColor, toggleMenuState, 
  setMetaDescription, setMetaTitle, setMetaImage } from '../store/actions/action';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./assets/scss/PostsGrid.scss";
import "./assets/scss/watch.scss"
import Video from "./components/Video";
import ReactPlayer from 'react-player';


class Watch extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      popupVideo: false,
      isLoading : true,
      featuredVideos : [],
      simpleVideos : []
    }
    this.content = [];
  
    this.slickSettings = {
      dots: true,
      dotsClass: "vertical-dots",
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => {
        const featuredVideos = this.state.featuredVideos.map((d, i)=>{
          if(next===i){
            d.playing = true;
          }else{
            d.playing = false;
          }
          return d;
        })
        this.setState({ activeSlide: next, featuredVideos })
      },
      afterChange: current => window.scrollTo(0, 0),
      customPaging: i => ( <div>{`0${i + 1}`} <span/></div> ),
      appendDots: dots => (
        <div>
          <ul> {dots} </ul>
        </div>
      )
    };
    
    this.ytOpts = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0,
        playsinline: 0,
        modestbranding: 1,
        color: '#1a17af'
      }
    };
  }

  reactPlayerReady = () => {
  }

  componentDidMount () {
    this.props._setCurrentRoute('watch');
    this.props._setHeaderColor('white');
    // this.props._openMenu(false);

    const PageUrl = process.env.REACT_APP_API_URL + "/youthfirst/page/watch"
		axios.get(PageUrl).then(({data}) => {
        const featuredVideos = data.page_data.filter(d=>d.is_featured).map((d, i)=>{
            if(i===0) d.playing = true;
            else d.playing = false;
            return d;
        })
        const simpleVideos = data.page_data.filter(d=>!d.is_featured);
        this.setState({isLoading : false, featuredVideos, simpleVideos});

        this.props._setMetaTitle(data.page_title);
        this.props._setMetaDescription(data.excerpt);
        this.props._setMetaImage(data.cover);
        
        window.scrollTo(0, 0);
    })
    
    document.addEventListener('keyup', (e) => {
      if (e.key === "Escape") {
        this.closePopup();
      }
    });
  }
  
  goBack=()=>{
    // this.props.history.push("/watch")
    this.closePopup();
  }

  setpopupVideo = (popupVideo, slug, e)=>{
    e.preventDefault();
    this.setState({
      popupVideo
    })
  }

  closePopup = () => {
    this.setState({ popupVideo : false });
  }
 
  render() {
    if(this.state.isLoading) return <div/>;
    return (
      <div>
        <div className="watch-page">
          <div className="cover-slider">
            <Slider {...this.slickSettings}>
            {
                this.state.featuredVideos.map((video,key)=>{
                    let style = {};
                    if(video.image.large){
                      style.backgroundImage = `url(${video.image.large})`;
                    }
                    return(
                      <div className="v-slide" key={video.slug}>
                          <div className="v-slide-text">
                              <h2 className="v-title">{video.name}</h2>
                              <p className="v-desc">{video.excerpt}</p>
                              {video.video_url &&
                                <button 
                                    className="watch-btn" 
                                    onClick={(e)=>this.setpopupVideo(video.video_url,video.slug, e)}>WATCH NOW
                                </button>
                              }
                          </div>
                          <div className="trans-layer"/>
                          {video.short_video &&
                            <Video 
                              classProp="slide-video" 
                              poster={video.image.large} 
                              src={video.short_video} 
                              type={video.short_video_format}
                              playing={video.playing}
                            />
                          }
                          <div className="bglayer" style={style} />
                      </div>
                    )
                })
            }
            </Slider>
          </div>

          <div className="grid-container">
            <div className="grid videos-grid">
              {
                this.state.simpleVideos.map((post, key)=>{
                  const style = {
                    backgroundImage : `url(${post.image.thumb})`
                  }
                  return(
                    <div className="grid-item" key={post.slug}>
                      <NavLink 
                        to={`watch/${post.slug}`} 
                        id={post.slug} 
                        className="full-background content-center-container-v" 
                        style={style}
                        onClick={(e)=>this.setpopupVideo(post.video_url, post.slug, e)}>
                        <div className="">
                          <h2 className="post-title">{post.name}</h2>
                          <div className="post-desc" dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                          {post.video_url &&
                            <button className="btn blue">
                                WATCH NOW
                            </button>
                          }
                        </div>
                      </NavLink>
                    </div>
                  )
                })
              }
            </div>
          </div>
          
          {this.state.popupVideo &&
            <div className={`fixedPopup ${this.state.popupVideo ? 'show' : ''}`}>
              <div className="close-btn" onClick={this.closePopup}></div>
              <div className="valign" style={{color:'#FFF'}}>Loading...</div>
              <ReactPlayer url={this.state.popupVideo} onReady={this.reactPlayerReady()} style={{position:'relative', zIndex:'2'}} playing width="100%" height="100%"/>
            </div>
          }
          
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        // _openMenu : (isOpen) => {
        //   dispatch( toggleMenuState(isOpen) )
        // },
        _setMetaTitle: (param) => {
          dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(Watch);