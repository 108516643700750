import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import "../assets/scss/header.scss";
import MenuButton from './MenuButton'
import svgSprite from '../assets/images/sprite.svg'
import { homeSlideCounter, firstSlideCounter } from '../../store/actions/action';

class Header extends Component{


	headerLogoHandler = (e) => {
		e.preventDefault();
		// const el = e.target;
		if(this.props._currentRoute==='home'){
			this.props._sethomeSlideCounter(0);
			this.props._setFirstSlideCounter(1);
		} else {
			this.props.history.push( '/' );
		}
	}
	
	render(){
		const header = this.props.header;
		if(header===false) return null

		const showLogo = (this.props._currentRoute==='home' && this.props._homeSlideCounter===0 && this.props._firstSlideCounter===1) ? false : true;
		const showBacktoExplore = (typeof this.props._currentRoute==='object' && this.props._currentRoute.sub==='explore-state');

		return(
			<header className={`site-header ${this.props._headerColor} ${(this.props._hpCounter===0) ? 'hidden1' : ''}`}>
				<div className="container">
					<div className="site-logo">
						{showLogo &&
							<a href="/" onClick={this.headerLogoHandler}>
								<img src={header.logo_white} alt={header.alt} className="white" />
								<img src={header.logo_blue} alt={header.alt} className="blue" />
							</a>
						}
					</div>
					<div className="header-right">
						{showBacktoExplore &&
							<a className="back-to-explore" href="/explore" onClick={e=>{
								e.preventDefault();
								if(this.props.history.action==='POP'){
									this.props.history.push('/explore');
								}else{
									this.props.history.goBack();
								}
							}}>
								<span className="icon icon-usa">
									<svg><use xlinkHref={`${svgSprite}#icon-usa`} /></svg>
								</span>
								<strong>{this.props.windowDimensions.width>480 ? 'BACK TO EXPLORE MAP' : 'BACK'}</strong>
							</a>
						}
						{showLogo &&
							<MenuButton />
						}
					</div>
				</div>
			</header>
		)
	}

}

const mapStateToProps = (state) => {
	return {
		_headerColor: state.rootReducer.headerColor,
		_homeSlideCounter : state.rootReducer.homeSlideCounter,
		_firstSlideCounter : state.rootReducer.firstSlideCounter,
		_currentRoute : state.rootReducer.currentRoute,		
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
		_sethomeSlideCounter: (counter) => {
			dispatch(homeSlideCounter(counter))
		},
		_setFirstSlideCounter: (counter) => {
			dispatch(firstSlideCounter(counter))
		},
	})
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
