//https://www.d3-graph-gallery.com/graph/barplot_stacked_basicWide.html
//https://observablehq.com/@vajana/d3-stacked-bar-chart
import React, { Component } from 'react';
import * as d3 from "d3";

class StackedBarChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.colors = {};
        this.labels = {};
        this.columns = {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.breakpoint != prevProps.breakpoint) {
          this.renderChart();
        }
    }
    
    componentDidMount(){
        this.renderChart();
    }

    renderChart = (breakpoint=this.props.breakpoint) => {
        
        let size = breakpoint=='desktop' ? 900 : 320;

        const margin = {top: 10, right: 20, bottom: 20, left: 25},
            width = size - margin.left - margin.right;
        
        let height = size - margin.top - margin.bottom;

        if(breakpoint=='desktop')
            height = Math.ceil( height - (size/2) );

        this.chartRef.current.innerHTML = '';
        const svg = d3.select(this.chartRef.current)
                        .append("svg")
                        .attr("width", width + margin.left + margin.right)
                        .attr("height", height + margin.top + margin.bottom)
                        .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        
        const {stackedData} = this.props;

        // let data = stackedData['data'];

        let data = stackedData['data'].map((item)=>{
            let zero_value = []
            for(const key in item)
            {
                if(item[key]===0)
                    zero_value.push(key);
            }
            if(zero_value.length)
            {
                zero_value.map((key)=>{
                    delete item[key];
                })
            }
            return item;
        })
        
        // this.columns = Object.keys(stackedData['data'][0]);
        this.columns = ["group", "white", "black", "hispanic", "american_indian", "asian", "other"];

        this.columns.map((key)=>{
            if( undefined !== stackedData['meta'][key]){
                this.colors[key] = stackedData['meta'][key].color;
                this.labels[key] = stackedData['meta'][key].label;
            }
        })
        
        const subgroups = this.columns.slice(1);
        const groups = d3.map(data, function(d){return(d.group)}).keys();

        let x='';
        if(breakpoint=='desktop'){
            x = d3.scaleBand()
                .domain(groups)
                .range([0, width-250])
                .paddingInner([0.15])
                .paddingOuter([0.05]);
        }
        else{
            x = d3.scaleBand()
                .domain(groups)
                .range([0, width])
                .padding([0.1]);
        }

        const y = d3.scaleLinear()
            .domain([0, 100])
            .range([ height, 0 ]);
        
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .style("font-size", function(){return breakpoint=='desktop' ? 11 : 9;})
            .call(d3.axisBottom(x).tickSizeOuter(0));

        svg.append("g").call(d3.axisLeft(y));

        // console.log(subgroups, data)
        const stack = d3.stack().keys(subgroups)(data);

        const colors = this.colors;
        const labels = this.labels;
        let bars = svg.append("g")
            .selectAll("g")
            .data(stack)
            .enter()
            .append("g")
                .attr("fill", function(d) { return colors[d.key]; })
                .selectAll("rect")
            .data(function(d) {
                return d; 
            })
            .enter();
            
        let barsg = bars.append("g");

        barsg.append("rect")
            .attr("x", function(d) { return x(d.data.group); })
            .attr("y", function(d) {  return y(d[1])===NaN ? 0 : y(d[1]); })
            .attr("height", function(d) { return y(d[0]) - y(d[1]); })
            .attr( "width", function(d){ return (d.data.group!='Incarcerated Youth Population' && breakpoint=='desktop') ? 80 : x.bandwidth(); } )
            // .attr( "width", function(d){ return x.bandwidth(); } )
            .append("title")
            .text(function(d){
                const parent = d3.select(this.parentNode.parentNode.parentNode).datum();
                const t = d.data[parent.key];
                return t>0 ? `${t}% ${labels[parent.key]}` : ''; 
            })
            .style("text-anchor", "middle");

        barsg.append("text")
            .attr("x", function(d) {
                const width =  x.bandwidth();
                
                if(breakpoint=='desktop')
                    return d.data.group!='Incarcerated Youth Population' ? x(d.data.group) + 80 : x(d.data.group) + width;
                
                return x(d.data.group) + width/2 - 15; 
            })
            .attr("y", function(d) { 
                const height = y(d[0]) - y(d[1]); 
                return y(d[1]) + height/2;
            })
            .text(function(d) {
                const parent = d3.select(this.parentNode.parentNode).datum();
                const t = d.data[parent.key];
        
                if(breakpoint==='desktop')
                    return t>0 ? `— ${t}% ${labels[parent.key]} Youth` : ''; 
                
                return t>0 ? `${t}%` : ''; 

                    
            })
            .style("fill", function(){
                const parent = d3.select(this.parentNode.parentNode).datum();
                if(breakpoint=='desktop')
                    return colors[parent.key];
                return '#000';
            })
            .style("font-size", '12px')
            .style("alignment-baseline", function(){
                if(breakpoint=='desktop')
                    return false;
                return "central";
            });
    }
    

    render(){
        return(
            <div className="chart-container">
                <div ref={this.chartRef} />
                <div className="chart-legend">
                    {this.columns.map && 
                        this.columns.map((key)=>{
                            return key==='group' 
                                ? null 
                                : (<label key={key}><span style={{backgroundColor:this.colors[key]}}></span>{this.labels[key]}</label>)
                        })
                    }
                </div>
            </div>
        );
    }
}


export default StackedBarChart