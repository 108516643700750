import React from 'react';
import { ReactComponent as Fb } from "../assets/social-icons/facebook.svg"
import { ReactComponent as Twitter } from "../assets/social-icons/twitter.svg"
import { ReactComponent as Mail } from "../assets/social-icons/mail.svg"
import "../assets/scss/SocialShare.scss";

const SocialShare = ( props ) => {
	// ? encodeURIComponent(`${props.text}`) : ""
	const title = encodeURIComponent(props.title),
		  description = encodeURIComponent(props.description),
		  to = encodeURIComponent(props.to),
		  twitterText = props.twitterText ? encodeURIComponent(props.twitterText) : title,
		  twurl = `https://twitter.com/intent/tweet?text=${twitterText}${encodeURIComponent(" #NoKidsInPrison")}&url=${to}`,
		  fburl = `https://www.facebook.com/dialog/share?display=page&href=${to}&app_id=${process.env.REACT_APP_FB_ID?process.env.REACT_APP_FB_ID:""}`;

		return(
		<div className={`social-share ${props.className || ""}`}>
			{props.label &&
				<label>{props.label}</label>
			}
			<div className="icons">
				<a href={`${fburl}`} target="_blank" rel="noopener noreferrer" className="fb"><Fb width={16} height={28} /></a>
				<a href={`${twurl}`} target="_blank" rel="noopener noreferrer" className="twitter"><Twitter width={22} height={19} /></a>
				<a href={`mailto:?subject=${title}&body=${description} \r\n\r\n ${to}`} className="mail"><Mail width={24} height={18} /></a>
			</div>		
		</div>
	)

}


export default SocialShare;