import React, { Component } from 'react';
import { connect } from 'react-redux';
import { geoAlbersUsa, scaleLinear } from "d3";
import Map from "./Map"
import Text from "./Text"
import { currency, toTop } from '../../../helpers/helpers'
import Gradient from "./Gradient"
import { setCurrentRoute, setHeaderColor, toggleMenuState,
    setMetaDescription, setMetaTitle, setMetaImage } from '../../../store/actions/action';

const CostDataNaN = () => {
    return (
        <div className="default-css">
            <p><span className="" style={{'display':'inline-block',width:'20px',height:'20px',backgroundColor:'#000',marginRight:'5px',borderRadius:'2px'  }}></span>Data Not Available</p>
        </div>
    )
}
class Costs extends Component {
    
    state = {
        stateColors: [],
    };

    maxConfinementCost = 1;
    minConfinementCost = 1;

    colorMap = scaleLinear()
    .domain([1, 10])
    .range(["#0fba9a", "#0f5246"]);

    componentDidMount(){

        this.props._setCurrentRoute({'current':'explore', 'sub':'costs'});
        this.props._setHeaderColor('blue');
        // this.props._openMenu(false);

        this.props._setMetaTitle('Costs Per State');
        this.props._setMetaDescription('Costs Per State');
        this.props._setMetaImage('');

        this.maxConfinementCost = Math.max(...this.props.localitiesData.map(o => o.annual_confinement_cost), 0);
        this.minConfinementCost = Math.min(...this.props.localitiesData.filter(o => o.annual_confinement_cost!=='').map(o => o.annual_confinement_cost), this.maxConfinementCost);
        const stateColors = this.props.localitiesData.map((d,i)=>({
            'id' : d.map_id,
            'color' : d.annual_confinement_cost ? this.colorMap((+d.annual_confinement_cost/+this.maxConfinementCost)*10) : '#000'
        }))
        this.setState({stateColors});
        toTop();
    }

    projection() {
        return geoAlbersUsa()
          .scale(this.props.width)
          .translate([ this.props.width / 2, this.props.height / 2 ]);
    }

    render(){
        
        if(this.state.stateColors.length===0) return null;
        return (
            <div className="container">
                <div className="map-container">
                    <div className="mobile-only">
                        <Gradient 
                            gradientClass="green"
                            meta={[
                                `$${currency(this.minConfinementCost)}`,
                                `$${currency(this.maxConfinementCost)}`
                            ]}
                        />
                        <CostDataNaN/>
                    </div>
                    <Map
                        id="cost-interactive"                    
                        width={ this.props.width } 
                        height={ this.props.height }
                        svgPaths={this.props.svgPaths}
                        markers={[]}
                        stateColors={this.state.stateColors}
                    />
                    <div className="map-detail">
                        <Text
                            h2={this.props.exploreData.page_data.costs.title}
                            html={this.props.exploreData.page_data.costs.content}
                        />
                        <div className="map-meta desktop-only">
                            <Gradient 
                                gradientClass="green"
                                meta={[
                                    `$${currency(this.minConfinementCost)}`,
                                    `$${currency(this.maxConfinementCost)}`
                                ]}
                            />
                            <CostDataNaN/>
                            <div className="default-css">
                                <p>Click on any state above to see what it costs to incarcerate one child in that state.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
  }
} //end class

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _openMenu : (isOpen) => {
          dispatch( toggleMenuState(isOpen) )
        },
        _setMetaTitle: (param) => {
          dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(Costs);