import React from 'react';
import NavLink from 'react-router-dom/NavLink';
const AdjacentPosts = ( adjacent_posts ) =>{
	// console.log(adjacent_posts);
	return(
		<div className="adjacent-posts-container">
			{
				adjacent_posts.posts.prev_post &&
				<div className="adj-post prev-post">
					<NavLink 
						to={adjacent_posts.posts.prev_post.slug}
						key={adjacent_posts.posts.prev_post.slug}>
						{adjacent_posts.posts.prev_post.title}
					</NavLink>
				</div>
			}
			{
				adjacent_posts.posts.next_post &&
				<div className="adj-post next-post">
					<NavLink 
						to={adjacent_posts.posts.next_post.slug}
						key={adjacent_posts.posts.next_post.slug}>
						{adjacent_posts.posts.next_post.title}
					</NavLink>
				</div>
			}
		</div>
	)
}

export default AdjacentPosts;