import React from 'react';
import { NavLink } from 'react-router-dom';
import "../assets/scss/PostsGrid.scss";

const PostGrid = ( props ) => {
	// console.log(props.posts);
	const posts = props.posts,
		  linkTo = props.linkTo;
	if( posts ){
		return(
				<div className="grid-container">
					<div className="grid">
						{
							posts.map( post => {
								const link = linkTo + '/' + post.slug,
										postExcerpt = post.hide_excerpt===false ? post.excerpt : '',
										style = {
											backgroundImage : `url(${post.thumbnail})`
										};
		
								return (
									<div className="grid-item" key={post.slug}>
										<NavLink to={link} id={post.slug} className="full-background content-center-container" style={style}>
											<div className="content-center">
												<h2 className="post-title">{post.name}</h2>
												<div className="post-desc" dangerouslySetInnerHTML={{ __html: postExcerpt }}></div>
												<span className='btn'>Learn More</span>							
											</div>
										</NavLink>
									</div>
								)
							} )
						}
					</div>
				</div>
		)
		
	}else{
		return ("No posts")
	}
}

export default PostGrid;