import React, { Component }  from 'react';
import { Route, Redirect, Switch  } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Bowser from "bowser";

import { toggleMenuState } from './store/actions/action';


import './pages/assets/scss/app.scss';

import {getScrollPostion, getWindowDimensions, getNextRoute, getPrevRoute} from './helpers/helpers'
import Header from  './pages/components/Header';
import NavMenu from './pages/components/NavMenu'
// import ArrowNavigator from './pages/components/ArrowNavigator'
import Footer from  './pages/components/Footer';
// import homeBg from './pages/assets/images/home-bg.jpg'
import Home from './pages/Home'
import Explore from './pages/Explore'
import Watch from './pages/Watch'
import PostsPage from  './pages/PostsPage'
import PostShare from  './pages/PostShare'
import Post from './pages/Post'
// import PostPlaylist from './pages/PostPlaylist'
import Blog from './pages/Blog'
import ContactUs from './pages/ContactUs'

// import Map from './pages/Map'


class App extends Component {

    state = {
        isLoading: true,
        stickyHeader: false,
        windowDimensions: 0,
        componentAnimating: false,
        canLoadNext : false,
        canLoadPrev : false,
        osClass : ""
    };
    header = false;
    navMenu = false;
    footer = false;
    onWheelTimeout = 0;
    browser = Bowser.getParser(window.navigator.userAgent).parsedResult;

    componentDidMount(){
        this.init()
        const appDataUrl = process.env.REACT_APP_API_URL + "/youthfirst/app"
        const hash = window.location.hash.substr(1);
        if(hash==='menu'){
            this.props._openMenu(true);
        }
		axios.get(appDataUrl).then(({data}) => {
            this.header = data.header;
            this.navMenu = data.navMenu;
            this.footer = data.footer;
			this.setState({
                isLoading: false
            })
        })
    }

    init = () => {
        this.onAppScroll();
        this.onAppResize();

        localStorage.removeItem('pageNumber');

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            this.osClass = "os-android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            this.osClass =  "os-ios";
        }

        // this.osClass =  "";
    }
    
    setStickyHeader = () => {
        let stickyHeader = false
        const scrollPosition = getScrollPostion();
        if(scrollPosition[1]>10)
            stickyHeader = true;
        this.setState({stickyHeader});
    }
    
    onAppScroll = () => {
        this.setStickyHeader();
        let onScrollTimeout = 0;
        window.addEventListener('scroll', () => {
            clearTimeout(onScrollTimeout);
            onScrollTimeout = setTimeout(() => {

                this.setStickyHeader();
                /*
                if(this.props._currentRoute!=='home' && this.state.componentAnimating===false){

                    const scrollPosition = getScrollPostion();
                    if ((window.innerHeight + Math.ceil(scrollPosition[1])) >= document.body.scrollHeight) {
                        const next = getNextRoute(this.props._currentRoute, this.navMenu);
                        if(next){
                            this.props.history.push(next.route)
                        }
                    }
                }
                */
            }, 200);
        });
    }

    onAppResize = () => {
        this.setState({'windowDimensions': getWindowDimensions()});
        let onResizeTimeout = 0;
        window.addEventListener('resize', () => {
            clearTimeout(onResizeTimeout);
            onResizeTimeout = setTimeout(() => {
                this.setState({'windowDimensions' : getWindowDimensions()});
            }, 200);
        });
    }

    onAppWheel = (e) => {
		clearTimeout(this.onWheelTimeout);
        const deltaY = e.deltaY;
		this.onWheelTimeout = setTimeout(() => {
            // console.log(deltaY);
            // const scrollPosition = getScrollPostion();
            
            // const isMenuitem = this.navMenu.filter(x=>x.route===`/${this.props._currentRoute}`);
			
            if(this.props._currentRoute!=='home' && this.state.componentAnimating===false /*&& isMenuitem.length>0*/){
                const scrollPosition = getScrollPostion();
                if (deltaY < 0) {
                    if(scrollPosition[1]===0){
                        const prev = getPrevRoute(this.props._currentRoute, this.navMenu);
                        if(prev){
                            this.props.history.push(prev.route)
                        }


                        // if(this.state.canLoadPrev){
                        //     const prev = getPrevRoute(this.props._currentRoute, this.navMenu);
                        //     if(prev){
                        //         this.props.history.push(prev.route)
                        //     }
                        //     this.setState({canLoadPrev:false})
                        // } else {
                        //     this.setState({canLoadPrev:true})
                        // }
                    }
                }
                else if (deltaY > 0) {

                    if ((window.innerHeight + Math.ceil(scrollPosition[1])) >= document.body.scrollHeight) {
                        const next = getNextRoute(this.props._currentRoute, this.navMenu);
                        if(next){
                            this.props.history.push(next.route)
                        }


                        // if(this.state.canLoadNext){
                        //     const next = getNextRoute(this.props._currentRoute, this.navMenu);
                        //     if(next){
                        //         this.props.history.push(next.route)
                        //     }
                        //     this.setState({canLoadNext:false})
                        // } else {
                        //     this.setState({canLoadNext:true})
                        // }
                    }
                }
                    
			}
		}, 300);
	}

    render() {
        if(this.state.isLoading) return null;
        let siteContainer = {
            style: {},
            classes: ""
        }
        if(typeof this.props._currentRoute === 'object'){
            siteContainer.classes = `${this.props._currentRoute.current} ${this.props._currentRoute.sub}`;
        }else{
            siteContainer.classes = this.props._currentRoute;
        }
        siteContainer.style = this.props._currentRoute==='home' ? {
            backgroundImage: "",//`url(${homeBg})`,
            backgroundSize: 'cover'
        } : {};
        
        return (
            <div 
                style={siteContainer.style} 
                /*onWheel={this.onAppWheel}*/ 
                onScroll={this.pageScroll} 
                className={`site-container ${this.state.componentAnimating?'componentAnimating':''} ${this.state.stickyHeader ? 'page-scrolled' : ''} ${siteContainer.classes} ${this.osClass?this.osClass:""}`}>
                <Helmet>
                    <title>{this.props._metaTitle}</title>
                    <meta name="description" content={this.props._metaDescription} />
                    <meta property="og:title" content={this.props._metaTitle} />
                    <meta property="og:description" content={this.props._metaDescription} />
                    <meta property="og:image" content={this.props._metaImage} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@nokidsinprison" />
                    <meta name="twitter:title" content={this.props._metaTitle} />
                    <meta name="twitter:description" content={this.props._metaDescription} />
                    <meta name="twitter:image" content={this.props._metaImage} />
                </Helmet>
                
                {/* 
                <div id="spinner" className={this.state.componentAnimating?'fadeIn':'fadeOut'}>
                    <img src={this.header.logo_blue} alt={this.header.alt} />
                </div>
                */}
                
                <Header 
                    header={this.header} 
                    routeProps={this.props} 
                    windowDimensions={this.state.windowDimensions}
                    history={this.props.history}
                />
                <div className="content-container">
                    <div className="content-container-inner">
                        <NavMenu isMenuOpen={this.props._isMenuOpen} navMenu={this.navMenu} />
                    <Switch>
                        <Route exact path='/explore' render={ ()=> <Redirect to="/explore/racial-disparity-of-incarcerated-youth"/> } /> 
                        <Route exact path='/explore/:tab'  render={({location})=><Explore windowDimensions={this.state.windowDimensions}/>} /> 

                        {/* <Route 
                            exact 
                            path='/take-action/share/single/:slug' 
                            component={PostShare}
                            key="take-action-share-single" 
                        /> */}
                        
                        <Route exact path = '/take-action/share/page/:pageParam?' component={PostShare}  key="take-action-share" />
                        <Route exact path = '/take-action/share/single/:slug?' component={PostShare}  key="take-action-share" />
                        <Route exact path = '/take-action/share' component={PostShare}  key="take-action-share" />
                        
                        <Route
                            render={({ location }) => {
                                return (
    
                            <TransitionGroup>
                                <CSSTransition
                                    key={location.key}
                                    timeout={1000}
                                    classNames="fade"
                                    onEnter = {(node, isAppearing)=>{this.setState({componentAnimating:true})}}
                                    onExited = {()=>{this.setState({componentAnimating:false})}}>

                                        <Switch location={location}>
                                            <Route exact path='/' render={ ()=> <Home browser={this.browser} navMenu={this.navMenu} /> } /> 
                                            
                                            {/* <Route exact path='/explore' render={ ()=> <Redirect to="/explore/racial-disparity-of-incarcerated-youth"/> } /> 
                                            <Route exact path='/explore/:tab'  render={({location})=><Explore windowDimensions={this.state.windowDimensions}/>} /> 
                                             */}

                                            <Route path='/watch/:id?' component={Watch} /> 
                                            
                                            <Route exact path='/take-action' key="take-action" component={PostsPage} />
                                            <Route exact path='/learn-more' key="learn-more" component={PostsPage} />
                                            <Route exact path='/contact-us' key="contact-us" component={ContactUs} />
                                            <Route exact path='/about-us/news/:filter?/:filterParam?/:page?/:pageParam?' component={Blog} />
                                            <Route exact path='/about-us' key="about-us" component={PostsPage} />
                                            <Route exact path='/solutions' key="solutions" component={PostsPage} />
                                            
                                            <Route exact path = '/take-action/share' component={PostShare}  key="take-action-share" />
                                            {/* <Route exact path = '/take-action/share/:page?/:pageParam?' component={PostShare}  key="take-action-share" /> */}
                                            {/* <Route exact path = '/take-action/share/single/:slug?' component={PostShare}  key="take-action-share" /> */}

                                            <Route exact path = '/take-action/:post' component={Post}  key="take-action-post" />
                                            <Route exact path = '/about-us/:post' component={Post} key="about-us-post" />
                                            <Route exact path = '/solutions/:post' component={Post}  key="solutions-post" />

                                            <Route path= '/learn-more/:post' component={Post} />
                                            
                                            <Route path="/:post" component={Post} key="post"/>
                                        </Switch>

                                </CSSTransition>
                            </TransitionGroup>
                                );
                            }}
                        />
                    </Switch>
                    </div>
                    {/* <ArrowNavigator menu={this.navMenu} /> */}
                </div>
                <Footer navBar={this.navMenu} {...this.footer} history={this.props.history}  />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
	return {
		_isMenuOpen: state.rootReducer.isMenuOpen,
		_currentRoute: state.rootReducer.currentRoute,
		_metaTitle: state.rootReducer.metaTitle,
		_metaDescription: state.rootReducer.metaDescription,
		_metaImage: state.rootReducer.metaImage,
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _openMenu : (isOpen) => {
			dispatch( toggleMenuState(isOpen) )
    	}
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(App);