import React from 'react';
import { NavLink } from 'react-router-dom';
import {fDate} from '../../helpers/helpers';
const BlogPosts = ( props ) =>{
	// console.log("hello world");
	const posts = props.posts;
	if(posts===-1){
		return <div className="blog-posts">Posts not found.</div>
	}
	else if( posts ){

		return(
			<div className="blog-posts">
				{
					posts.map( ( post , key) => {
						const date = new Date(post.date);
						return(
							<div className="blog-post" key={key}>
								<article>
									<div className="blog-post-header">
										<h2 className="name"><NavLink exact={true} to={`/${post.slug}`} dangerouslySetInnerHTML={{__html: post.title.rendered}}></NavLink></h2>
										<div className="meta">
											<div className="date">{fDate(date)}</div>
										</div>
										<div>
											<div style={{'display':'inline'}} className="content excerpt" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}/>
											<div style={{'display':'inline'}} className="readmore"><NavLink exact={true} to={`/${post.slug}`}>Read More</NavLink></div>
										</div>
									</div>
								</article>
							</div>
						)
					} )
				}
				{props.paging}
			</div>
		)
		
	} else {
		return <div className="blog-posts">Loading...</div>
	}

	// return <div className="no-posts"></div>
}

export default BlogPosts;