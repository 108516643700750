
import React,{Component} from 'react';
import { geoAlbersUsa, geoPath} from "d3";
import { withRouter } from 'react-router-dom';
import { slugify } from '../../../helpers/helpers'
import pinM from '../../assets/images/pin-male.svg';
import pinF from '../../assets/images/pin-female.svg';
import pinB from '../../assets/images/pin-both.svg';


class MapLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapScale: false,
            mapTranslate: false,
            // activeState: false
        }
    }
    
    projection(){
        return geoAlbersUsa()
          .scale(this.props.width+300)
          .translate([ this.props.width / 2, this.props.height / 2 ]);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedDropdown!==this.props.selectedDropdown){
            if(this.props.selectedDropdown){

                const d = this.props.svgPaths.filter( d => this.props.selectedDropdown === d.id );
                const path = geoPath().projection(this.projection())
                if(d.length){
                    const bounds = path.bounds(d[0]),
                         dx = bounds[1][0] - bounds[0][0],
                         dy = bounds[1][1] - bounds[0][1],
                         x = (bounds[0][0] + bounds[1][0]) / 2,
                         y = (bounds[0][1] + bounds[1][1]) / 2;

                    let scale = .5 / Math.max(dx / this.props.width, dy / this.props.height);   
                    if(scale>2){
                        scale=2;
                    } 

                    const translate = [this.props.width / 2 - scale * x, this.props.height / 2 - scale * y];

                    this.setState({
                        mapScale: scale,
                        mapTranslate: translate,
                    })
                }else{
                    this.setState({
                        mapScale: false,
                        mapTranslate: false
                    })
                }
            }else{
                this.setState({
                    mapScale: false,
                    mapTranslate: false
                })
            }
        }
    }

    handlePinClick = (name, id) => {
        this.props.history.push(`${slugify(name)}?section=facilities&facility=${id}`);
    }
    render() { 
        let transform = "";
        if(this.state.mapTranslate && this.state.mapScale){
            transform = `translate(${this.state.mapTranslate})scale(${this.state.mapScale})`;
        }
        return (
            <svg width={ this.props.width } height={ this.props.height } viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
                <g className="states" transform={transform}>
                    {
                        this.props.svgPaths.map((d,i) => {
                            const stateColor = this.props.stateColors.filter(usState=>usState.id===d.id);
                            return (<g key={ `path-${ i }` }><path
                                key={ `path-${ i }` }
                                d={ geoPath().projection(this.projection())(d) }
                                id={d.id}
                                fill={ stateColor[0].color }
                                className={`state ${(d.id===this.props.selectedDropdown)?'active-state':null}`}
                                stroke="#FFFFFF"
                                strokeWidth={ 1 }
                            /><title>{d.properties.name}</title></g>);
                        })
                    }
                    {this.props.markers.length &&
                        <g className="markers">
                        {
                            this.props.markers.map((marker, key) => {
                                const lat  = marker.lat,
                                    long = marker.long;
                                let pin = pinM;

                                if(marker.gender==='F') pin = pinF;
                                else if(marker.gender==='B') pin = pinB;
                                else pin = pinM;

                                return (
                                    <g key={key} className={`${marker.closed?'closed':''}`}><image 
                                        xlinkHref={pin} 
                                        height="28" 
                                        width="20"
                                        x={ this.projection(this.props)([`${long}`, `${lat}`])[0] }
                                        y={ this.projection(this.props)([`${long}`, `${lat}`])[1] }
                                        className={`marker ${marker.closed?' closed':''}`}
                                        onClick={()=>this.handlePinClick(marker.state, marker.id)}
                                    /><title>{marker.name}</title></g>
                                )
                            })
                        }
                        </g>
                    }
                </g>

            </svg>
        );
    }        
}
 
export default withRouter(MapLocations);