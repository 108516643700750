import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Accordion from "accordion/src/accordion.mjs";
import "accordion/src/accordion.css";
import { setCurrentRoute, setHeaderColor, setMetaDescription, setMetaTitle, setMetaImage, toggleMenuState } from '../store/actions/action';

import Cover from './components/CoverD';
import './assets/scss/blocks.scss';
import './assets/scss/post.scss';

import PostPlaylist from './components/PostPlaylist'
import PostMeta from './components/PostMeta';
import AdjacentPosts from './components/AdjacentPosts';
class Post extends Component {

    state = {
        isLoading: true
    };
    content : false;
    
    componentDidMount(){
        const postSlug = this.props.match.params.post;
        this.props._setCurrentRoute({current:postSlug, sub:'single-post'});
        this.props._setHeaderColor('white');
        // this.props._openMenu(false);
        const postUrl = process.env.REACT_APP_API_URL + "/youthfirst/post/" + postSlug
        axios.get(postUrl).then(({data}) => {
            this.content = data;

            this.props._setMetaTitle(data.post_title);
            this.props._setMetaDescription(data.excerpt);
            this.props._setMetaImage(data.cover);

            this.setState({ isLoading : false });
            for(const el of document.querySelectorAll(".accordion")){
                new Accordion(el,{
                    noAria: true,
                    heightOffset: 10
                });
            }
            for(const el of document.querySelectorAll(".default-css a>img")){
                el.parentNode.className += " noborder";
            }
            window.scrollTo(0, 0);
        })
    }

    render() {
        
        if(this.state.isLoading) return <div/>;
        if(this.content.playlist===true){

            return <div><PostPlaylist content={this.content} /></div>

        }else if( this.content.post_type == "post" ){
             return (
                <div className="blog-post">
                    <Cover 
                        title={this.content.post_title} 
                        cover={this.content.cover} 
                        content={this.content.excerpt}
                    />
                    <div className="container">
                        <div className="default-post default-css">
                            <PostMeta date={this.content.date} category={this.content.category} />
                            <div dangerouslySetInnerHTML={{__html: this.content.page_content}} />
                            <AdjacentPosts posts={this.content.adjacent_posts} />
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div>
                    <Cover 
                        title={this.content.post_title} 
                        cover={this.content.cover} 
                        content={this.content.excerpt}
                    />
                    <div className="container">
                        <div className="default-post default-css">
                            <div dangerouslySetInnerHTML={{__html: this.content.page_content}} />
                        </div>
                    </div>
                </div>
            );

        }
    }
}


const mapStateToProps = (state) => {
    return {
    // nav_menus : state.rootReducer.nav_menu,
    // hp_counter:state.rootReducer.hp_counter,
    // home_data: state.rootReducer.home_data
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        _setCurrentRoute: (route) => {
        dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Post);
// export default Post;