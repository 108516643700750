import React from 'react';
import { NavLink } from 'react-router-dom';
import "../assets/scss/PostsGrid.scss";
import SocialShare from './SocialShare';
import { ReactComponent as Play } from "../assets/social-icons/play-button.svg"


const PostGridSocial = ( props ) => {

	const posts = props.posts,
		  defaultExcerpt = "I wanted to share this Media from https://www.nokidsinprison.org/ with you",
		  socialURL = `${process.env.REACT_APP_BASE_URL}`;
	
	if( posts ){
		return(
			posts.length ?
				<div className="masonry-container">
					<div className="masonry">
						{
							posts.map( (post, i) => {
								const link = `/take-action/share/single/${post.slug}`;

								return (
									<div key={post.slug} className={`${post.orientation}`}>
										<NavLink to={link} id={post.slug} className="grid-content">
											<img src={post.thumbnail} alt={post.name} />
											{post.video_id &&
												<div className="play"><Play width={100} height={100} /></div>
											}
											{/* <div className="content-center">
												<h2 className="post-title">{post.name}</h2>
												<div className="post-desc" dangerouslySetInnerHTML={{ __html: postExcerpt }}></div>
												<span className='btn'>Learn More</span>
											</div> */}
										</NavLink>
										{props.socialShare &&
											<SocialShare 
												to={`${socialURL}/take-action/share/single/${post.slug}`} 
												title={post.name}
												description={post.excerpt ? post.excerpt : defaultExcerpt}
												twitterText={post.name}
											/>
										}
									</div>
								)
							} )
						}
					</div>
				</div>
			:
				<div className="masonry-container">Loading...</div>
		)
		
	}else{
		return (
			<div className="masonry-container">
				No posts found...
			</div>
		)
	}
}

export default PostGridSocial;