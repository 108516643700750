import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleMenuState } from '../../store/actions/action';
import "../assets/scss/MenuButton.scss";


class MenuButton extends Component{

    menuBtnHandler = () => {
        if(this.props.close===true){
            const hash = window.location.hash.substr(1);
            if(hash==='menu')
                window.location.hash = "";
            this.props._openMenu(false);
        }
        else{
            window.location.hash = 'menu';
            this.props._openMenu(true);
        }
    }
    
    render(){
        const _class = this.props.close===true ? 'close' : ''
        return (
            <div className={`menu-btn ${_class}`} onClick={this.menuBtnHandler}>
                <div className="bar bar1"></div>
                <div className="bar bar2"></div>
                <div className="bar bar3"></div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return({
		_openMenu : (isOpen) => {
			dispatch( toggleMenuState(isOpen) )
		}
	})
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);