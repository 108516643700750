import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogSidebar = (props) => {
	// console.log("its sidebar here");
	// console.log(props.content);
	const sidebar_content = props.content;
	if( sidebar_content ){
		const recent_posts = sidebar_content.recent_posts,
				date_archives = sidebar_content.months_archive,
				categories = sidebar_content.categories;
		return(
			<div className="sidebar-widgets-container">
				<div className="widget">
					<h2 className="name">Recent Posts</h2>
					<div className="content">
						<ul>
							{
								recent_posts.map( recent_post => {
									return ( 
										<li key={recent_post.slug}>
											<NavLink activeClassName="noborder" to={`/${recent_post.slug}`} dangerouslySetInnerHTML={{__html: recent_post.title}}></NavLink>	
										</li> 
									)
								} )
							}
						</ul>
					</div>
				</div>
				<div className="widget">
					<h2 className="name">Archive</h2>
					<div className="content">
						<ul>
							{
								date_archives.map( date_archive => {
									return ( 
										<li key={date_archive.sulg}>
											<NavLink activeClassName="noborder" to={`/about-us/news/date/${date_archive.sulg}`} dangerouslySetInnerHTML={{__html: date_archive.month}}></NavLink>	
										</li> 
									)
								} )
							}
						</ul>
					</div>
				</div>
				<div className="widget">
					<h2 className="name">Categories</h2>
					<div className="content">
						<ul>
							{
								categories.map( category => {
									return ( 
										<li key={category.slug}>
											<NavLink activeClassName="noborder" to={`/about-us/news/category/${category.slug}`} dangerouslySetInnerHTML={{__html: category.name}}></NavLink>	
										</li> 
									)
								} )
							}
						</ul>
					</div>
				</div>
			</div>
		)
	}

	return false;
}

export default BlogSidebar;