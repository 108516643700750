import React, { Component } from 'react';
import axios from "axios";

class ContactUsForm extends Component {

	state = {
		name : '',
		email: '',
		zip_code: '',
		message : '',
		
		show_form_message : false,
		status: false,
		status_message : '',
		is_form_being_submited : false
	}

    handleChange( field_name , e){
    	const new_value = e.target.value;
    	switch( field_name ){
    		case 'name':
    			this.setState({ name : new_value }); 
    		break;
    		case 'email':
    			this.setState({ email : new_value }); 
    		break;
    		case 'zip_code':
    			this.setState({ zip_code : new_value }); 
    		break;
    		case 'message':
    			this.setState({ message : new_value }); 
    		break;
			default:
				return null;
    	}
    }
    submitForm(e){
		e.preventDefault();
        this.setState({show_form_message:false})
		if( this.state.is_form_being_submited )
			return
		
		this.setState({is_form_being_submited:true})
		let bodyFormData = new FormData();
		bodyFormData.append('_wpcf7', this.props.formId );
	    bodyFormData.append('your_name', this.state.name);
	    bodyFormData.append('your_email', this.state.email);
	    bodyFormData.append('your_subject', this.state.zip_code);
	    bodyFormData.append('your_message', this.state.message);

		const cf7_url = process.env.REACT_APP_API_URL + "/contact-form-7/v1/contact-forms/" + this.props.formId + "/feedback";

	    axios({
        method: 'post',
        url: cf7_url,
        data: bodyFormData,
        config: { headers: {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8' } }

        
      	}).then((response) => {
        	// console.log(response);
        	const status = response.data.status;
        	const message = response.data.message;
        	this.setState({
				status,
				status_message:message,
				show_form_message:true,
				is_form_being_submited:false
        	})
			if( status === 'mail_sent' ){
				this.setState({
					name : '',
					email : '',
					zip_code : '',
					message : ''
				})
			}
        	// console.log(response.into);
      	})
      	.catch(function(error) {
        	console.log(error);
      	});
    }
    render() {
    	// let contect_us_form_class
        return(
            <div className={ this.state.is_form_being_submited ? 'contcat-us-form form-being-submited' : "contcat-us-form" }>
				<form method="post">
					<p>
						<input type="text" name="name" onChange={(e) => {this.handleChange( 'name' , e ) }} placeholder="Your Name" value={this.state.name}/>
					</p>
					<p>
						<input type="email" name="email" onChange={(e) => {this.handleChange( 'email' , e ) }} placeholder="Your Email" value={this.state.email}/>
					</p>
					<p>
						<input type="text" name="zip_code" onChange={(e) => {this.handleChange( 'zip_code' , e ) }} placeholder="Zip Code" value={this.state.zip_code}/>
					</p>
					<p>
						<textarea name="message" cols="20" rows="10" onChange={(e) => {this.handleChange( 'message' , e ) }} placeholder="Your Message" value={this.state.message}></textarea>
					</p>
					<p>
						<input type="submit" name="submit" onClick={(e) => { this.submitForm(e) }}/>
					</p>
					<p className={ this.state.show_form_message ? 'show_form_message' : "hide_form_message" }>
						<span className={ this.state.status ? this.state.status : "hide" }>
							{this.state.status_message}
						</span>
					</p>
				</form>
            </div>
        );
    }
}
export default ContactUsForm;
