import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Cover from './components/Cover';
import PostsGrid from './components/PostsGrid';
import { setCurrentRoute, setHeaderColor, toggleMenuState, 
    setMetaDescription, setMetaTitle, setMetaImage } from '../store/actions/action';

class PostsPage extends Component {

    state = {
        loading : false,
    };
    content = false;
    postType = false;

    componentDidMount(){
        this.postType = this.props.location.pathname.substring(1); //this.props.match.path.substring(1)
        this.props._setCurrentRoute(this.postType);
        this.props._setHeaderColor('blue');
        // this.props._openMenu(false);
        
        const PageUrl = process.env.REACT_APP_API_URL + "/youthfirst/page/" + this.postType
		axios.get(PageUrl).then(({data}) => {
            this.content = data;
            this.setState({loading:true});

            this.props._setMetaTitle(data.og_title ? data.og_title : data.page_title);
            this.props._setMetaDescription(data.excerpt);
            this.props._setMetaImage(data.cover);
            
            window.scrollTo(0, 0);
        })
    }
    
    render() {
        if(this.state.loading===false) return <div/>;
        return (
            <div>
                <div className={`${this.postType}-posts`}>
                    <Cover 
                        title={this.content.page_title} 
                        cover={this.content.cover} 
                        content={this.content.excerpt}
                    />
                    {this.content.page_content &&
                        <div className="container">
                            <div className="default-css" dangerouslySetInnerHTML={{__html: this.content.page_content}} />
                        </div>
                    }
                    <PostsGrid 
                        posts={this.content.page_data} 
                        linkTo={this.postType} 
                    />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
        _setCurrentRoute: (route) => {
          dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _openMenu : (isOpen) => {
          dispatch( toggleMenuState(isOpen) )
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsPage);