export const NAV_MENU = "NAV_MENU";
export const HOME_SLIDE_COUNTER = "HOME_SLIDE_COUNTER";
export const FIRST_SLIDE_COUNTER = "FIRST_SLIDE_COUNTER";
export const CURRENT_ROUTE = "CURRENT_ROUTE";
export const CURRENT_SUB_ROUTE = "CURRENT_SUB_ROUTE";
export const IS_MENU_OPEN = "IS_MENU_OPEN";
export const HEADER_COLOR = "HEADER_COLOR";

export const META_TITLE = "META_TITLE";
export const META_URL = "META_URL";
export const META_DESCRIPTION = "META_DESCRIPTION";
export const META_IMAGE = "META_IMAGE";

export const IS_LOADING = "IS_LOADING";