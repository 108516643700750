import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleMenuState } from '../../store/actions/action';
import MenuButton from './MenuButton';
import "../assets/scss/navmenu.scss";

	
class NavMenu extends Component {
	
	constructor(props){
		super(props);
		this.onScrollTimeout = 0;
	}
	onMenuWheel = (e) => {
		if(this.props._currentRoute!=='home') return;
		// console.log(this.props.history);
		clearTimeout(this.onScrollTimeout);
		const deltaY = e.deltaY;
		this.onScrollTimeout = setTimeout(() => {
			if (deltaY < 0) {
				// console.log('scrolling up');
				this.props._openMenu(false);
			}
			if (deltaY > 0) {
				this.props.history.push('/watch')
			}
		}, 300);
	}
	onTouchStart = (e) => {
		if(this.props._currentRoute!=='home') return;
		this.ts = e.touches[0].clientY;
	}
	onTouchEnd = (e) => {
		if(this.props._currentRoute!=='home') return;
		var te = e.changedTouches[0].clientY;
		if(this.ts > te+5){

			this.props.history.push('/watch')

		} else if (this.ts < te-5) {

			this.props._openMenu(false);

		}
	}
	onNavClick = (e) => {
		e.preventDefault();
		const el = e.target;
		const href = el.getAttribute('href');
		this.props.history.push( href );
		this.props._openMenu(false);
	}
	render(){
		const menus = this.props.navMenu;
		if(menus===false) return null
		return(
			<React.Fragment>
				<div 
					onTouchStart = {e => this.onTouchStart(e)} 
					onTouchEnd = {e => this.onTouchEnd(e)} 
					onWheel={(e) => this.onMenuWheel(e)} 
					className={`nav-menu-container-grid ${this.props.isMenuOpen?'fadeIn':'fadeOut'}`}>
					<MenuButton close={true} />
					<div className="nav-menus">
						{
							menus.map( ( menu , key) =>{
								// let menu_title = menu.title.toLowerCase();
								// menu_title = menu_title.replace(/\s+/g, '-');
								
								let navClass = 'nav-item';
								if(`/${this.props._currentRoute}`===menu.route){
									navClass += ' active'
								}
	
								const style = {
									backgroundImage : `url(${menu.image})`
								}

								return(
									menu.slide.hide_from_hamburger_menu==1 ? null :
									<a 
										onClick={menu.refresh_page==1 ? window.location.reload : this.onNavClick} 
										style={style} 
										href={menu.route} 
										className={navClass} 
										key={menu.page_id}>
										<span className="avoid-clicks">{menu.title}</span>
										<img className="avoid-clicks" style={{'display':'none'}} alt={menu.title} src={menu.image} />
									</a>
								)
							})
						}
					</div>
				</div>
			</React.Fragment>
		)

	}
}


const mapStateToProps = (state) => {
	return {
		_currentRoute: state.rootReducer.currentRoute,
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
		_openMenu : (isOpen) => {
			dispatch( toggleMenuState(isOpen) )
    	}
	})
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenu));