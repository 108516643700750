import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentRoute, setHeaderColor, homeSlideCounter, firstSlideCounter, toggleMenuState,
  setMetaDescription, setMetaTitle, setMetaImage, setIsLoading } from '../store/actions/action';

import Slider from "react-slick";
import axios from 'axios';
import Video from "./components/Video";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {ReactComponent as Arrow} from "./assets/images/arrow.svg"

import "./assets/scss/home.scss";
import "./assets/scss/arrownavigator.scss";

class Home extends Component {
  
  constructor(props){
    super(props);
    this.isSliding = false;
    this.state = {
      firstSlide : [],
      slides: []
    };

    this.ts = '';
    this.onScrollTimeout = 0;

    this.slickSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      draggable: false,
      
      afterChange: (current) =>{

        this.isSliding = false;
        const slides = this.state.slides.map((d, i)=>{
          
          if((current-1)===i){
            
            d.slide.video.playing = true;

          } else {
            
            d.slide.video.playing = false;
          
          }
          return d;

        })
        this.setState({ slides })

        //text over video fix for safari
        setTimeout(() => {
          if(
            (this.props.browser.os.name==='iOS' || this.props.browser.os.name==='macOS') &&
            this.props.browser.browser.name==='Safari'
          ){
            let vs = document.querySelectorAll(".slide-video");
            let i=0;
            for (i = 0; i < vs.length; i++) {
              let v = vs[i];
              v.play();
              v.setAttribute('controls', true);
            }
          }
        }, 400);  
      },
      
      beforeChange: (current, next) => {
        
        //text over video fix for safari
        if(
          (this.props.browser.os.name==='iOS' || this.props.browser.os.name==='macOS') &&
          this.props.browser.browser.name==='Safari'
        ){
          let vs = document.querySelectorAll(".slide-video");
          let i=0;
          for (i = 0; i < vs.length; i++) {
            let v = vs[i];
            v.pause();
            v.setAttribute('controls', false);
          }
        }

        this.isSliding = true;
      
      }
    };

  }
  
  homeSlideUp = (e) => {

    if(this.state.firstSlide.length>0 && this.isSliding===false){
      
      // console.log('slideup');

      const activeSlide = this.props._homeSlideCounter,
            activeInFirstSlide = this.props._firstSlideCounter;

      if(activeSlide===0) {
        
        if(activeInFirstSlide===1){
          
          return

        } else {
          
          this.props._setFirstSlideCounter(activeInFirstSlide-1);
        
        }

      } else {

        this.props._sethomeSlideCounter(activeSlide - 1);
      
      }

    }

  }
  
  homeSlideDown = (e) => {

    if(this.state.firstSlide.length>0 && this.isSliding===false){
      
      // console.log('slidedown');
      
      const activeSlide = this.props._homeSlideCounter,
              activeInFirstSlide = this.props._firstSlideCounter;
      
      if(activeSlide===0 && activeInFirstSlide < this.state.firstSlide.length) {
      
        this.props._setFirstSlideCounter(activeInFirstSlide+1);
      
      } else {

        if(activeSlide < this.state.slides.length)
          this.props._sethomeSlideCounter(activeSlide + 1);
      
      }

    }
    
  }
  
  onHomeWheel = (e) => {
    clearTimeout(this.onScrollTimeout);
		const deltaY = e.deltaY;
		this.onScrollTimeout = setTimeout(() => {
			if (deltaY < 0) {
				this.homeSlideUp();
			}
			else if (deltaY > 0) {        
        this.homeSlideDown();
			}
		}, 300);
  }
  
  onTouchStart = (e) => {
    this.ts = e.touches[0].clientY;
  }
  
  onTouchEnd = (e) => {
    var te = e.changedTouches[0].clientY;
    if(this.ts > te+5){

      this.homeSlideDown();

    } else if (this.ts < te-5) {

      this.homeSlideUp();

    }
  }

  handleArrowClick = () => {
    if(this.state.slides.length > this.props._homeSlideCounter){
      this.homeSlideDown();
    } else {
      this.homeSlideUp();
    }
  }

  componentDidUpdate(prevProps) {
		if (this.props._homeSlideCounter!==prevProps._homeSlideCounter) {
      if(this.slider && this.props._homeSlideCounter>=0){
        this.slider.slickGoTo( this.props._homeSlideCounter );
      }
		}
	}

  componentDidMount() {
    
    this.props._sethomeSlideCounter(0);
    this.props._setFirstSlideCounter(1);
    this.props._setIsLoading(true);
    this.props._setCurrentRoute('home');
    this.props._setHeaderColor('white');
    this.setState({'slides': this.props.navMenu});

    const homeDataUrl = process.env.REACT_APP_API_URL + "/youthfirst/page/home";
    axios.get(homeDataUrl).then(({data})=>{
      
      this.props._setMetaTitle(data.page_title);
      this.props._setMetaDescription(data.page_content);
      this.props._setMetaImage(data.cover);
      
      this.setState({
        firstSlide: data.page_data.slides
      });
      this.props._setIsLoading(false);
    })
  }

  componentWillUnmount(){
    this.props._sethomeSlideCounter(0);
    this.props._setFirstSlideCounter(1);
  }
  
  render(){
    const menus = this.state.slides,
          firstSlide = this.state.firstSlide,
          style = `html, body {margin: 0; height: 100%; overflow: hidden; backgorund-color: #000;}`;
    if(menus===false || this.props._isLoading===true || firstSlide<1) return <div/>
      return(
          <div 
            className="homepageslides cover-slider" 
            onTouchStart = {e => this.onTouchStart(e)} 
            onTouchEnd = {e => this.onTouchEnd(e)} 
            onWheel={(e) => this.onHomeWheel(e)}
          >
          <style>{style}</style>
            <Slider {...this.slickSettings} ref={slider => (this.slider = slider)}>
              <div className="fullpageslide">
                <div>
                  <div>

                    <div className="firstslide">
                      {firstSlide.map( (slide, i) => {
                        return (
                          <div className={`hp-slide-container ${this.props._firstSlideCounter===(i+1) ? "active" : ""}`} key={i}>
                            <div className="hp-slide" style={{backgroundImage:`url(${slide.background})`}}>
                              <div className="slide-data">
                                {slide.icon &&
                                  <img className="icon" src={slide.icon} alt={slide.title} />
                                }
                                {slide.title &&
                                  <h2>{slide.title}</h2>
                                }                     
                                <div dangerouslySetInnerHTML={{__html: slide.content}}></div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  
                  </div>
                </div>
              </div>
              {menus.map( ( menu , key) =>{
                  const slide = menu.slide,
                        style = {
                          backgroundImage : `url(${slide.image})`
                        };

                  let url = '';
                  if(menu.refresh_page==1){
                    url = <a 
                      href={menu.route} 
                      onClick={() => window.location.reload()} 
                      className="watch-btn">{slide.button_text}</a>
                  }
                  else{
                    url = <NavLink
                        to={menu.route} 
                        className="watch-btn">
                          {slide.button_text}
                      </NavLink>;
                  }
                  return(
                    <div className="fullpageslide v-slide" key={menu.page_id}>
                      <div className="v-slide">
                        <div className="v-slide-text">
                            <h2 className="v-title">{slide.title}</h2>
                            <div className="v-desc">{slide.text}</div>
                            {url}
                        </div>
                        <div className="trans-layer"/>
                        {slide.video.src &&
                          <Video 
                            classProp="slide-video" 
                            // poster={slide.video.poster} 
                            src={slide.video.src} 
                            type={slide.video.type}
                            playing={slide.video.playing}
                          />
                        }
                        <div className="bglayer" style={style} />
                      </div>
                    </div>
                  );
              })}
            </Slider>
          
            <div className={`arrow-container ${menus.length===this.props._homeSlideCounter?"reverse":""}`}>
              <div className="container">
                <div className="arrow">
                  <Arrow width={38} height={24} onClick={this.handleArrowClick} />
                </div>
              </div>
            </div>
            
          </div>
      );
    }
} //end class

const mapStateToProps = (state) => {
	return {
    _homeSlideCounter : state.rootReducer.homeSlideCounter,
    _firstSlideCounter : state.rootReducer.firstSlideCounter,
    _isLoading : state.rootReducer.isLoading,
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
    _setCurrentRoute: (route) => {
      dispatch(setCurrentRoute(route))
    },
    _setHeaderColor: (color) => {
      dispatch(setHeaderColor(color))
    },
    _sethomeSlideCounter: (counter) => {
      dispatch(homeSlideCounter(counter))
    },
    _setFirstSlideCounter: (counter) => {
      dispatch(firstSlideCounter(counter))
    },
    _openMenu : (isOpen) => {
			dispatch( toggleMenuState(isOpen) )
    },
    _setMetaTitle: (param) => {
      dispatch(setMetaTitle(param))
    },
    _setMetaDescription: (param) => {
        dispatch(setMetaDescription(param))
    },
    _setMetaImage: (param) => {
        dispatch(setMetaImage(param))
    },
    _setIsLoading: (param) => {
      dispatch(setIsLoading(param))
    }
	})
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);