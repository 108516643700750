import React, { Component } from 'react';
// import axios from 'axios';
import { connect } from 'react-redux';
import Scrollspy from 'react-scrollspy';
import jump from "jump.js";
import { setCurrentRoute, setHeaderColor, setMetaDescription, setMetaTitle, setMetaImage } from '../../store/actions/action';
import svgSprite from '../assets/images/sprite.svg'
import Cover from './Cover';
import '../assets/scss/post-playlist.scss';

class PostPlaylist extends Component {

    state = {
        isLoading: true,
        isScrolling: false,
        menuOpen: false
    };
    content : false;
    currentSection : false;
    
    componentDidMount(){
        // const postSlug = this.props.match.params.post;
        // this.props._setCurrentRoute('single-post');
        // this.props._setHeaderColor('white');
        // const postUrl = process.env.REACT_APP_API_URL + "/youthfirst/post/" + postSlug
        // axios.get(postUrl).then(({data}) => {
            this.content = this.props.content;
            const data = this.props.content;

            // this.props._setMetaTitle(data.post_title);
            // this.props._setMetaDescription(data.excerpt);
            // this.props._setMetaImage(data.cover);

            this.content.chapterIds = data.chapters.map(chapter=>chapter.id);
            this.setState({
                isLoading : false
            });
            for(const el of document.querySelectorAll(".default-css a>img")){
                el.parentNode.className += " noborder";
            }
            window.scrollTo(0, 0);
    }
    
    onScrollSpyUpdate = (el) => {
        if(el!==undefined){
            this.currentSection = el.id;
            // console.log(this.currentSection);
            // window.location.hash = el.id;
        }
    }

    onChapterClick = (e) => {
        e.preventDefault();
        this.setState({isScrolling: true});
        this.jump( e.currentTarget.getAttribute('href').substr(1) );
    }
    
    prevSection = () => {
        let index = this.content.chapters.findIndex(chapter=>chapter.id===this.currentSection);
        index = index - 1;
        if(index === -1) index = 0;
        const prev = this.content.chapters.filter((chapter, key)=>(index===key));
        this.jump(prev[0].id);
    }
    nextSection = () => {
        let index = this.content.chapters.findIndex(chapter=>chapter.id===this.currentSection);
        index = index + 1;
        if(index === this.content.chapters.length) index = index-1;
        const next = this.content.chapters.filter((chapter, key)=>(index===key));
        // if(typeof next==='object' && next.length>0)
        this.jump(next[0].id);
    }

    toggleMenu = () => {
        this.setState({menuOpen:!this.state.menuOpen})
    }
    
    jump = (sectionId) => {
        const node = document.getElementById(sectionId);
        jump(node, {
            duration: 500,
            offset: -100,
            callback: () => {
                node.className += " current-section";
                this.setState({isScrolling: false});
            }
        })
    }

    render() {
        
        if(this.state.isLoading) return <div/>;
        return (
            <div className="post-playlist">
                <Cover 
                    title={this.content.post_title} 
                    cover={this.content.cover} 
                    content={this.content.excerpt}
                />
                <div className="post-playlist-container">
                    <aside id="playlist-sidebar" className="sidebar">
                        <div className={`playlist-chapters ${this.state.menuOpen?'open':''}`}>
                            <div className="hamburger" onClick={this.toggleMenu}>
                                <div className="menu-btn ">
                                    <div className="bar bar1"></div>
                                    <div className="bar bar2"></div>
                                    <div className="bar bar3"></div>
                                </div>
                            </div>
                            <div className="playlist-back-btn" onClick={this.prevSection}>
                                <span className="s-arrow arrow-back"></span>
                            </div>
                            <div className="playlist-index">
                                <Scrollspy 
                                    items={this.content.chapterIds} 
                                    currentClassName={this.state.isScrolling ? "scrolling" : "is-current"} 
                                    offset={-110}
                                    onUpdate={(e)=>this.onScrollSpyUpdate(e)}>
                                {
                                    this.content.chapters.map((chapter, key)=>{
                                        return(
                                            <li key={key}>
                                                <a onClick={(e)=>this.onChapterClick(e)} href={`#${chapter.id}`}>
                                                    {/* <span className='index-count'>{key+1}</span> */}
                                                    {chapter.title}
                                                    <span className="tooltip">{chapter.tooltip}</span>
                                                </a>
                                            </li>
                                        );
                                    })
                                }
                                </Scrollspy>
                            </div>
                            <div className="playlist-next-btn" onClick={this.nextSection}>
                                <span className="s-arrow arrow-next"></span>
                            </div>
                        </div>
                    </aside>
                    <div className="post-playlist-content">
                        {this.content.chapters.map((chapter, i)=>{
                            const jsx = chapter.content.map((section, j)=>{
                                if(section.acf_fc_layout==='text'){
                                    return (
                                        <div key={j} className="text-block">
                                            <div className="container">
                                                <div className="default-css clearfix" dangerouslySetInnerHTML={{__html: section.text}} />
                                            </div>    
                                        </div>
                                    );
                                }else if(section.acf_fc_layout==='cover'){
                                    const style = {
                                        backgroundImage : `url(${section.cover.background_image})`,
                                        backgroundPosition : `${section.cover.background_image_position_x} ${section.cover.background_image_position_y}`
                                    };
                                    return(
                                        <figure key={j} className={`wp-block-image size-${section.cover.size}`}>
                                            <div className={`cover-block  align${section.cover.size} ${section.cover.size}-background`} style={style}>
                                                {section.cover.text &&
                                                    <div className="cover-block-text" dangerouslySetInnerHTML={{__html: section.cover.text}} />
                                                }
                                            </div>
                                            {section.cover.caption &&
                                                <figcaption dangerouslySetInnerHTML={{__html: `${section.cover.caption}`}}/>
                                            }
                                        </figure>
                                    );
                                }else if(section.acf_fc_layout==='pdf_block' && section.pdf.length>0){
                                    const pdfs = section.pdf.map((resource, k)=>(
                                            <p key={resource.label} className="resource-link">
                                                <a href={resource.url}>
                                                    <span className={`icon icon-pdf`}>
                                                        <svg><use xlinkHref={`${svgSprite}#pdf-icon`} /></svg>
                                                    </span>
                                                    <span>{resource.label}</span>
                                                </a>
                                            </p>
                                        ))
                                    return (
                                        <div key={j} className="text-block">
                                            <div className="block-resources-links">
                                                {pdfs}
                                            </div>
                                        </div>
                                    );
                                }
                                else return null;
                            })
                            return <section key={`section_${i}`} id={chapter.id}>{jsx}</section>;
                        })}
                    </div>
                    {/* <div className="fake-col" /> */}
                </div>
            
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    // nav_menus : state.rootReducer.nav_menu,
    // hp_counter:state.rootReducer.hp_counter,
    // home_data: state.rootReducer.home_data
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        _setCurrentRoute: (route) => {
        dispatch(setCurrentRoute(route))
        },
        _setHeaderColor: (color) => {
        dispatch(setHeaderColor(color))
        },
        _setMetaTitle: (param) => {
            dispatch(setMetaTitle(param))
        },
        _setMetaDescription: (param) => {
            dispatch(setMetaDescription(param))
        },
        _setMetaImage: (param) => {
            dispatch(setMetaImage(param))
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PostPlaylist);