import {
	NAV_MENU, HOME_SLIDE_COUNTER, FIRST_SLIDE_COUNTER, CURRENT_ROUTE, CURRENT_SUB_ROUTE, IS_LOADING,
	HEADER_COLOR, IS_MENU_OPEN, META_TITLE, META_URL, META_DESCRIPTION, META_IMAGE 
} from './types';


export function setIsLoading(param) {
	return{
		type : IS_LOADING,
		payload : param	
	}
}

export function homeSlideCounter(counter) {
	return{
		type : HOME_SLIDE_COUNTER,
		payload : counter	
	}
}

export function firstSlideCounter(counter) {
	return{
		type : FIRST_SLIDE_COUNTER,
		payload : counter	
	}
}

export function setCurrentRoute(route) {
	return {
		type: CURRENT_ROUTE,
		payload: route
	}
}

export function setCurrentSubRoute(route) {
	return {
		type: CURRENT_SUB_ROUTE,
		payload: route
	}
}

export function setNavMenu(navMenu) {
	return {
		type: NAV_MENU,
		payload: navMenu
	}
}

export function setHeaderColor(color) {
	return {
		type : HEADER_COLOR,
		payload: color
	}
}

export function toggleMenuState(isOpen) {
	return {
		type: IS_MENU_OPEN,
		payload: isOpen
	}
}

export function setMetaTitle(param) {
	return {
		type: META_TITLE,
		payload: param
	}
}
export function setMetaUrl(param) {
	return {
		type: META_URL,
		payload: param
	}
}
export function setMetaDescription(param) {
	return {
		type: META_DESCRIPTION,
		payload: param
	}
}
export function setMetaImage(param) {
	return {
		type: META_IMAGE,
		payload: param
	}
}