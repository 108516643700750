import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavLink from 'react-router-dom/NavLink';
import "../assets/scss/footer.scss";
import svgSprite from '../assets/images/sprite.svg'
import { homeSlideCounter } from '../../store/actions/action';

class Footer extends Component{

	footerNavClick = (e) => {
		e.preventDefault();
		const el = e.target;
		const href = el.getAttribute('href');
		this.props.history.push( href );

		/*
		if(this.props._currentRoute==='home'){
			const i = [...el.parentElement.children].indexOf(el) + 1;
			this.props._sethomeSlideCounter(parseInt(i));
		} else {
			const href = el.getAttribute('href');
			this.props.history.push( href );
		}
		*/
	}

	FooterNavbar(){
		const navItems = this.props.navBar;
		if( navItems ){

			return(
				<div className="footer-nav-bar">
					{
						navItems.map( (navItem,i) => {
							const navId = navItem.route.substring(1);
							return (
								navItem.refresh_page==1 ? 
								<a 	href={navItem.route} 
									onClick={() => window.location.reload()} 
									className="watch-btn">{navItem.title}
								</a>
								:
								<a 
									className={(this.props._currentRoute===navId || this.props._homeSlideCounter===(i+1))?'active':''}
									href={navItem.route} 
									key={navId}
									onClick={this.footerNavClick}>
									{navItem.title}
								</a>
							)
						} )
					}
				</div>
			)
		}
	}

	FooterNavigation(){
		const FooterNavigationLinks = this.props.navigation;
		if( FooterNavigationLinks ){
			return(
				<div className="footer-navigation">
					{
						FooterNavigationLinks.map( link => {
							const navId = link.route.substring(1);
							if ( link.route === '/home' ){
								link.route = '/';
							}
							return(
								<NavLink 
									to={link.route}
									key={navId}>
										{link.title}
								</NavLink>
							)
						} )
					}
				</div>
			)
		}
	}
	
	SocialMedia(){
		const socialMedialinks = this.props.social_media;
		if( socialMedialinks ){
			return(
				<div className="social-media-icons">
					{
						socialMedialinks.map( link => {
							return(
								// <div className="icon-container">
									<a href={link.url} key={link.name} className={`icon-container`} target="_blank" rel="noopener noreferrer">
										<span className={link.name}>
											<svg><use xlinkHref={`${svgSprite}#${link.name}`} /></svg>
										</span>
									</a>
								// </div>
							)
						} )
					}
				</div>
			)
		}
	}
	SignUp(){
		return(
			<h2 className="newsletter-button"><a href={this.props.news_letter_link} target="_blank" rel="noopener noreferrer">Sign Up For Updates</a></h2>
		);
	}

	render(){
		let footerClass = "";
		if(this.props._currentRoute==='home'){
			footerClass = this.props._homeSlideCounter===this.props.navBar.length ? "homeshow" : "homehidden";
		}
		return(
			<div className={`site-footer-container ${footerClass}`}>
				<div className="container">
					{  this.FooterNavbar() }
				</div>
				<div className="site-footer">
					<div className="container">
						<div className="site-footer-content">
							<div className="footer-navigation-container">
								{ this.FooterNavigation() }
							</div>
							<div className="social-media-and-newsletter">
								<div className="social-media">{this.SocialMedia()}</div>
								<div className="newsletter-container donation">
									<h2 class="newsletter-button">
										<a  href="https://secure.donationpay.org/nokidsinprison/" 
											target="_blank" 
											rel="noopener noreferrer"
										>Donate</a>
									</h2>
								</div>
								<div className="newsletter-container">{ this.SignUp() }</div>
							</div>
						</div>
					</div>
		        </div>
			</div>
		)
	}
}

// export default Footer;

const mapStateToProps = (state) => {
	return {
		_currentRoute: state.rootReducer.currentRoute,
		_homeSlideCounter : state.rootReducer.homeSlideCounter
	}
}

const mapDispatchToProps = (dispatch) => {
	return({
		_sethomeSlideCounter: (counter) => {
			dispatch(homeSlideCounter(counter))
		}
	})
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);