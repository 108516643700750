import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import Store from './store'

ReactDOM.render(
	<Provider store={Store}>
		<BrowserRouter>
			<Route path="/" component={App}/>
		</BrowserRouter> 
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
