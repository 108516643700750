import React from 'react';
// import "../assets/scss/cover.scss";
const PostMeta = ( props ) => {
	return(
		<div className="post-meta">
			<span className='post-meta-title'>{props.category}</span>
			<span className="post-date">{props.date}</span>
		</div>
	)
}

export default PostMeta;