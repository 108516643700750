
import * as React from "react";

const Gradient = (props) => {
    
  return (
    <div className={`gradient-container ${props.classNames || ""}`}>
        {props.gradientClass!=="none" &&
            <div className={`gradient-div ${props.gradientClass || ""}`}></div>
        }
        <div className="gradient-meta">
            {props.meta.map((o,i)=>{
                    if(typeof o === 'object')
                        return <span key={i} dangerouslySetInnerHTML={{__html:o.html}} className={o.className} />
                    else
                        return <span key={i} dangerouslySetInnerHTML={{__html:o}} />
                })
            }
        </div>
    </div>
  );
};

export default Gradient;