import React from 'react';
import "../assets/scss/cover.scss";
const Cover = ( props ) => {
	const cover_type = ( props.cover ? 'bg-image' : 'solid' );

	const style = props.cover ? {
		backgroundImage : `url(${props.cover})`
	} : {};
	
	return (
		<div className="cover-container">
			<div className={`cover full-background ${props.contentClasses || "content-center-container" } ${cover_type}`} style={style}>
				<div className="cover-content">
					<div className="container">
						<h1 className="title" dangerouslySetInnerHTML={{ __html: props.title }} />
						{props.content &&
							<div className="desc" dangerouslySetInnerHTML={{ __html: props.content }} />
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cover;